import { Ingestion } from "../../../../../component/ingestion/ingestion";
import { useParams } from "react-router";
import { getApiUrl } from "./../../../../../global";
import {
    StyledButtonContained,
    StyledButtonOutlined,
} from "../../../AccountCreation/accountCreation";
import axios from "axios";


export const AdminIngestion = (props) => {
    const params = useParams();
    const completeIngestion = async (status) => {
        if (status) {
            let _orderUUID = params.orderId;
            let url = await getApiUrl();

            let apiUrl =
                url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/";
            const payload = {
                status: status,
                uuid: _orderUUID,
                customer: props.orderDetail.customer,
            };
            axios
                .patch(apiUrl, payload, {
                    /*             headers: {
                                        "Content-Type": "multipart/form-data"
                                    }, */
                })
                .then((res) => {
                    // setStep(1);
                    if (res.data) {
                        props.getOrderDetail();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    };
    return (
        <>
            <Ingestion orderDetail={props.orderDetail} isAdmin={true} />
            {
                props?.orderDetail?.status == 'audit_complete' || props?.orderDetail?.status == 'ingestion_started' ?
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <StyledButtonContained

                            onClick={() => completeIngestion(props?.orderDetail?.status == 'audit_complete' ? 'ingestion_started' : props?.orderDetail?.status == 'ingestion_started' ? 'ingestion_complete' : '')}
                            disabled={!(props?.orderDetail?.status == 'audit_complete' || props?.orderDetail?.status == 'ingestion_started')}
                            variant="contained"
                            sx={{
                                width: "180px",
                                height: "50px",
                                marginTop: "6px"
                            }}
                        >
                            {props?.orderDetail?.status == 'audit_complete' ? 'Start Ingestion' : props?.orderDetail?.status == 'ingestion_started' ? 'Complete Ingestion' : ''}
                        </StyledButtonContained>
                    </div> : ''
            }
        </>

    )
}