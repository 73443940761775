import { useState, useEffect } from "react";
import { Grid, Paper, Box, Typography, Button, TextField, Tooltip, tooltipClasses, List, Link, IconButton, Tabs, Tab, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { HelpOutline, Close, CloudUploadOutlined, Article } from "@mui/icons-material";
import { ButtonColor, ButtonColorGradient } from "../theme/theme";
import { styled } from "@mui/styles";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from "prop-types";
import { Stack } from "@mui/system";
import { StyledButtonContained } from "../styled_components/styled_components";
import { getApiUrl } from "./../../global";
import axios from "axios";
import { useParams } from 'react-router-dom';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: 12,
    backgroundColor: '#3A3E3F',
    color: '#FFFFFF',
    maxWidth: 500,
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    border: '1px solid #FFFFFF',
    borderRadius: '12px',
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>{children}</>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const FileInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CustomTabList = styled(Tabs)(() => ({
  borderRadius: '12px 0px 0px 12px',
  '& .MuiTabs-scroller': {
    background: '#3A3E3F',
  },
  '& .MuiTabs-indicator': {
    background: '#FFC540 !important',
  },
  '& .MuiTab-root': {
    color: '#FFFFFF',
  },
  textTransform: 'none',
}));

const CustomTab = styled(Tab)(() => ({
  textTransform: 'none',
  color: '#FFFFFF !important',
  '&.Mui-selected': {
    color: '#FFC540 !important',
  }
}));

const allowedMimeTypes = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

const fileType = {
  USERTAPELIST: 'user_tape_list'
}
export const Requests = () => {
  const params = useParams();
  const [tapeListFiles, setTapeListFiles] = useState([]);
  const [virtualMachineFiles, setVirtualMachineFiles] = useState([]);

  const [virtualMachineUploadType, setVirtualMachineUploadType] = useState(0);

  useEffect(() => {
    getOrderDocuments();
  }, [])
  const getOrderDocuments = async () => {
    let _orderUUID = params.orderId;
    let url = await getApiUrl();

    let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/`;
    axios
      .get(apiUrl)
      .then(res => {
        let orderDoc = JSON.parse(JSON.stringify(res.data.results));
        orderDoc = orderDoc.filter((file) => file.file_type == fileType.USERTAPELIST);
        setTapeListFiles(orderDoc);
      })
      .catch(error => console.log(error));
  }

  const handleVMUploadTypeChange = (event, newValue) => {
    setVirtualMachineUploadType(newValue);
  };

  const [tapeListTooltipOpen, setTapeListTooltipOpen] = useState(false);
  const handleTapeListTooltipClose = () => {
    setTapeListTooltipOpen(false);
  };
  const handleTapeListTooltipOpen = () => {
    setTapeListTooltipOpen(true);
  };

  const [virtualMachineTooltipOpen, setVirtualMachineTooltipOpen] = useState(false);
  const handleVirtualMachineTooltipClose = () => {
    setVirtualMachineTooltipOpen(false);
  };
  const handleVirtualMachineTooltipOpen = () => {
    setVirtualMachineTooltipOpen(true);
  };

  const classes = useStyles();

  const handleTapeListFileSelect = (e) => {
    // console.log(e.target.files);
    if (e.target.files) {
      let selectedFiles = Array.from(e.target.files);
      selectedFiles.forEach((file) => {
        // if (allowedMimeTypes.includes(file.type)) {
        uploadFile([file], fileType.USERTAPELIST)
        // }
      });
    }
  };
  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })
  async function uploadFile(file, type) {
    const result = await toBase64(file[0]);
    let _orderUUID = params.orderId;
    let payLoadCustomer = {
      "file_type": type,
      "file": result.split(",")[1],
      "order": _orderUUID,
      "name": file[0].name,
      "file_size": file[0].size,
    }
    let url = await getApiUrl();
    let apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/`;
    axios.post(apiUrl, payLoadCustomer, {}).then((res) => {
      let newFiles = [];
      newFiles.push(res.data);
      setTapeListFiles([...tapeListFiles, ...newFiles]);
    }).catch(function (error) {
      console.log(error);
    });
  }

  const handleTapeListFileDrop = (e) => {
    e.preventDefault();
    let newFiles = [];
    if (e?.dataTransfer?.files) {
      console.log(e?.dataTransfer?.files);
      let selectedFiles = Array.from(e?.dataTransfer?.files);
      selectedFiles.forEach((file) => {
        if (allowedMimeTypes.includes(file.type)) {
          newFiles.push(file);
        }
      });
      setTapeListFiles([...tapeListFiles, ...newFiles]);
    }
  };

  const removeTapeListFileAttachment = (name, type) => {
    setTapeListFiles(tapeListFiles.filter((file) => file.name !== name));
  };

  const handleVirtualMachineFileSelect = (e) => {
    console.log(e.target.files);
    let newFiles = [];
    if (e.target.files) {
      newFiles = Array.from(e.target.files);
      setVirtualMachineFiles([...virtualMachineFiles, ...newFiles]);
    }
  };

  const handleVirtualMachineFileDrop = (e) => {
    e.preventDefault();
    let newFiles = [];
    if (e?.dataTransfer?.files) {
      let selectedFiles = Array.from(e?.dataTransfer?.files);
      selectedFiles.forEach((file) => {
        if (allowedMimeTypes.includes(file.type)) {
          newFiles.push(file);
        }
      });
      setVirtualMachineFiles([...virtualMachineFiles, ...newFiles]);
    }
  };

  const removeVirtualMachineFileAttachment = (name, type) => {
    setVirtualMachineFiles(virtualMachineFiles.filter((file) => file.name !== name));
  };

  const fetchVMSelectionFromLink = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response error');
        }
        return response;
      })
      .then((response) => {
        // Add response to VM Files List
      }).catch((error) => {
        console.log(error);
      });
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }
  const removeFile = async (uuid, type) => {
    let _orderUUID = params.orderId;
    let deleteDocumentPayload = { uuid: uuid }
    let url = await getApiUrl();

    let removeDocumentApiUrl = url + process.env.REACT_APP_API_BASE_URL + `/orders/${_orderUUID}/documents/` + deleteDocumentPayload.uuid + "/";
    axios.delete(removeDocumentApiUrl, deleteDocumentPayload, {}).then((res) => {
      if (type == fileType.USERTAPELIST) {
        setTapeListFiles(tapeListFiles.filter((file) => file.uuid !== uuid));
      }
      //  else {
      //   setBillFile(billFile.filter((file) => file.uuid !== uuid));
      // }

    }).catch(function (error) {
      console.log(error);
    });;
  }

  return (
    <Box height={'100vh'}>
      <Grid
        container
        direction="row"
        spacing={4}
        justifyContent="flex-start"
        height={'inherit'}
      >

        {/* Tape List */}
        <Grid item xs={4} height={'75%'}>
          <Grid container direction="column" height={'100%'}>
            {/* Tooltip */}
            <Grid item display={'flex'} justifyContent={'space-between'} height={'10%'}>
              <Typography
                align="left"
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                }}
              >
                Tape List
              </Typography>
              <CustomTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={tapeListTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <Grid container direction={'column'}>
                      <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                        <Typography>Tape List</Typography>
                        <IconButton onClick={handleTapeListTooltipClose} sx={{ cursor: 'pointer' }}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                      </Grid>
                      <Grid item xs={12} textAlign={'start'}>
                        <Typography>
                          Tape List is the list of files that are with you to verify that we have all the tapes. Please note the file should be:
                          <ul>
                            <li>
                              an XLS or CSV file
                            </li>
                            <li>
                              Please make the file under 10mb for this
                            </li>
                            <li>
                              <Link href="#" style={{ color: "#FFFFFF", whiteSpace: 'pre' }}>{'Click Here '}</Link>
                              if you would like to see a sample document
                            </li>
                          </ul>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }>
                <IconButton sx={{ height: '34px', width: '34px', cursor: 'pointer' }} onClick={handleTapeListTooltipOpen}><HelpOutline sx={{ color: '#FFFFFF' }} /></IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item height={'90%'} maxHeight={'fit-content'}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                height={'100%'}
                padding={2}
                style={{ background: '#3A3E3F', border: '1px solid #C7CCD0', borderRadius: '12px' }}
              >
                <Grid item height={'10%'}>
                </Grid>
                <Grid item maxHeight={'45%'} overflow={'auto'}>
                  <Grid
                    container
                    direction="column"
                    gap={1}
                  >
                    {tapeListFiles.map(file => (
                      <Grid item fullWidth display={'inline-flex'} justifyContent={'space-between'} alignItems={'center'}
                        sx={{
                          borderRadius: '8px',
                          color: '#FFFFFF',
                          border: '1px solid #FFFFFF',
                          background: '#3A3E3F',
                          mx: 4,
                          p: 1,
                        }}>
                        <Article sx={{ color: '#FFFFFF', height: '30px', width: '30px' }} />
                        <Stack direction={'column'} display={'flex'} alignItems={'flex-start'}>
                          <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.name}</Typography>
                          <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.file_size}</Typography>
                        </Stack>
                        <IconButton sx={{ cursor: 'pointer' }} onClick={() => { removeFile(file.uuid, fileType.USERTAPELIST) }}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item minHeight={'40%'} className={classes.dashedBorder} padding={1}>
                  <div onDrop={handleTapeListFileDrop} onDragOver={(e) => e.preventDefault()}>
                    <CloudUploadOutlined sx={{ color: '#FFFFFF' }} />
                    <Typography sx={{ color: '#FFFFFF', lineHeight: '28px', pb: 1 }}>Browse for the files on your computer or Drag them here</Typography>
                    <Button
                      size='large'
                      component='label'
                      variant='contained'
                      fullWidth
                      sx={{
                        width: '220px',
                        // background: ButtonColorGradient,
                        // borderRadius: '30px',
                        background: ButtonColorGradient,
                        borderRadius: '30px',
                        height: '60px',
                        // textTransform: 'none',
                        textTransform: 'none',
                      }}>
                      Add Files
                      <FileInput type='file' multiple onChange={handleTapeListFileSelect} />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>

        {/* Virtual Machine */}
        {/* <Grid item xs={4} height={'75%'}>
          <Grid container direction="column" height={'100%'}>
            <Grid item display={'flex'} justifyContent={'space-between'} height={'10%'}>
              <Typography
                align="left"
                style={{
                  fontSize: "28px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                }}
              >
                Virtual Machine
              </Typography>
              <CustomTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={virtualMachineTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <>
                    <Grid container direction={'column'}>
                      <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                        <Typography>Virtual Machine</Typography>
                        <IconButton onClick={handleVirtualMachineTooltipClose}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                      </Grid>
                      <Grid item xs={12} textAlign={'start'}>
                        <Typography>
                          Please Upload your virtual machine for us to use the
                          same environment as you are using for your tapes. You
                          can either upload a file to cloud and send us the link
                          or you can upload the VM here.
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }>
                <IconButton sx={{ height: '34px', width: '34px' }} onClick={handleVirtualMachineTooltipOpen}><HelpOutline sx={{ color: '#FFFFFF' }} /></IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item height={'90%'} maxHeight={'fit-content'}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                height={'100%'}
                padding={2}
                style={{ background: '#3A3E3F', border: '1px solid #C7CCD0', borderRadius: '12px' }}
              >
                <Grid item height={'10%'}>
                  <CustomTabList
                    value={virtualMachineUploadType}
                    onChange={handleVMUploadTypeChange}
                    sx={{
                      background: 'transparent'
                    }}
                  >
                    <CustomTab value={0} label="File" />
                    <CustomTab value={1} label="Link" />
                  </CustomTabList>
                </Grid>
                <Grid item maxHeight={'50%'} overflow={'auto'}>
                  <Grid
                    container
                    direction="column"
                    style={{ fontSize: "18px", color: "#FFFFFF" }}
                  >
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        gap={1}
                      >
                        {virtualMachineFiles.map(file => (
                          <Grid item fullWidth spacing={4} display={'inline-flex'} justifyContent={'space-between'}
                            sx={{
                              borderRadius: '8px',
                              color: '#FFFFFF',
                              border: '1px solid #FFFFFF',
                              background: '#3A3E3F',
                              mx: 4,
                            }}>
                            <Article sx={{ color: '#FFFFFF', height: '30px', width: '30px' }} />
                            <Stack direction={'column'} display={'flex'} alignItems={'flex-start'}>
                              <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.name}</Typography>
                              <Typography sx={{ fontSize: '14px', lineHeight: '20px', fontWeight: 400 }}>{file.size} bytes</Typography>
                            </Stack>
                            <IconButton onClick={() => { removeVirtualMachineFileAttachment(file.name, file.type) }}><Close sx={{ color: '#FFFFFF' }} /></IconButton>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item minHeight={'40%'} padding={1} className={(virtualMachineUploadType === 0) ? classes.dashedBorder : ''} >
                  <TabPanel value={virtualMachineUploadType} index={0}>
                    <div onDrop={handleVirtualMachineFileDrop} onDragOver={(e) => e.preventDefault()}>
                      <CloudUploadOutlined sx={{ color: '#FFFFFF' }} />
                      <Typography sx={{ color: '#FFFFFF', lineHeight: '28px', }}>Browse for the files on your computer or Drag them here</Typography>
                      <StyledButtonContained
                        size='large'
                        component='label'
                        variant='contained'
                        fullWidth
                        sx={{
                          width: '220px',
                          borderRadius: '30px',
                          height: '60px',
                        }}>
                        Add Files
                        <FileInput type='file' multiple accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleVirtualMachineFileSelect} />
                      </StyledButtonContained>
                    </div>
                  </TabPanel>

                  <TabPanel value={virtualMachineUploadType} index={1}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <TextField
                            className={classes.input}
                            placeholder="Insert Link here"
                            variant="outlined"
                            fullWidth
                          />
                          <Button onClick={fetchVMSelectionFromLink} className={classes.virtualMachineLinkButton} variant="contained">Go</Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <TextField
                            className={classes.input}
                            placeholder="Insert Link here"
                            variant="outlined"
                            fullWidth
                          />
                          <Button onClick={fetchVMSelectionFromLink} className={classes.virtualMachineLinkButton} variant="contained">Go</Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <TextField
                            className={classes.input}
                            placeholder="Insert Link here"
                            variant="outlined"
                            fullWidth
                          />
                          <Button onClick={fetchVMSelectionFromLink} className={classes.virtualMachineLinkButton} variant="contained">Go</Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <TextField
                            className={classes.input}
                            placeholder="Insert Link here"
                            variant="outlined"
                            fullWidth
                          />
                          <Button onClick={fetchVMSelectionFromLink} className={classes.virtualMachineLinkButton} variant="contained">Go</Button>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction={'row'}>
                          <TextField
                            className={classes.input}
                            placeholder="Insert Link here"
                            variant="outlined"
                            fullWidth
                          />
                          <Button onClick={fetchVMSelectionFromLink} className={classes.virtualMachineLinkButton} variant="contained">Go</Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}

      </Grid>
    </Box>
  );
};

const useStyles = makeStyles({
  input: {
    borderRadius: "8px 0px 0px 8px",
    border: "1px solid #FFFFFF !important",
    color: '#FFFFFF !important',
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: '#C7CCD0'
      }
    },
    "& .MuiOutlinedInput-input": {
      color: '#FFFFFF'
    },
  },
  dashedBorder: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='white' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '12px',
  },
  virtualMachineLinkButton: {
    background: ButtonColorGradient,
    borderRadius: '0px 11px 11px 0px !important',
    color: '#3A3E3F !important',
    textTransform: "none !important",
  }
});