import React, { useState } from 'react';
import {
    Button, Dialog, Box, TextField, FormControl, InputLabel, Select, MenuItem, Radio, RadioGroup,
    FormControlLabel, FormLabel, Grid, Typography, FormGroup, DialogContent, IconButton, InputAdornment, Snackbar
} from '@mui/material';
import { CheckCircle, Close, Error } from '@mui/icons-material';
import { useForm, useWatch } from 'react-hook-form';
import axios from 'axios';
//import { env } from '../../env';
import { getLoginCustomerId } from '../../LocalStorageService';
import { getApiUrl } from "../../global";
import { StyledButtonContained } from '../../views/Admin/AccountCreation/accountCreation';

const roles = [
    { label: "Super Admin", value: 'admin' },
    { label: 'Admin/Owner', value: 'owner' },
    { label: 'Order', value: 'creator' },
    { label: 'Member', value: 'member' },];
const country_codes = ['+61'];
const jobTypes = [
    { label: 'Permanent Employee', value: 'permanent' },
    { label: 'Contractor', value: 'contractor' },];

export const AddUser = ({ open, onClose, admin }) => {

    const { register, handleSubmit, } = useForm();

    const [salutation, setSalutation] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [role, setRole] = useState('');
    const [country, setCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [jobType, setJobType] = useState('');

    const handleClear = () => {
        setSalutation('');
        setFirstName('');
        setLastName('');
        setEmail('');
        setRole('');
        setPhoneNumber('');
        setCountry('');
        setJobType('');
        setPhoneNumberError(null);
        setEmailError(null);
    }

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            setEmailError(true);
        }
        else {
            setEmailError(false);
        }
    }

    const validatePhoneNumber = (phone) => {
        const formattedPhoneNumber = phone.replace(/\D/g, '');
        if (formattedPhoneNumber.length < 10) {
            setPhoneNumberError(true);
        }
        else {
            setPhoneNumberError(false);
        }
    }

    const [customerId, setCustomerId] = useState('a484c8d1-6ecc-412b-87e0-0584b9d1286b');

    const onSubmit = async (data) => {
        debugger
        // form submission here
        // localStorage.getItem(TapeArkLogin, enc);

        setCustomerId(getLoginCustomerId());
        let url = await getApiUrl();

        const apiUrl = admin ? url + `${process.env.REACT_APP_API_BASE_URL}` + `/users/` :
            url + `${process.env.REACT_APP_API_BASE_URL}` + `/customers/${getLoginCustomerId()}/users/`;

        const payload = {
            ...data,
            groups: [data.role],
            is_superuser: data.role == 'admin' ? true : undefined,
            //is_active: true,
            customer: getLoginCustomerId(),
        };
        console.log(payload);

        axios.post(apiUrl, payload)
            .then((response) => {
                console.log('POST request success:', response.data);
                openSnackbar('User Added successfully');
                handleClose();
            }).catch((err) => console.log(err));

    };

    const handleClose = () => {
        handleClear();
        onClose();
    };

    const [state, setState] = useState({
        openSb: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const { vertical, horizontal, openSb } = state;

    const openSnackbar = (message) => {
        setSnackbarMessage(message)
        setState({
            vertical: 'top', horizontal: 'right', openSb: true
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: 'top', horizontal: 'right', openSb: false
        });
    };

    return (<>
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSb}
            onClose={closeSnackbar}
            message={snackbarMessage}
            key={vertical + horizontal}
        />
        <Dialog open={open} fullWidth={true} onClose={handleClose}
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '8px',
                },
            }}>
            <DialogContent sx={{ p: 4, backgroundColor: '#3A3E3F', color: '#FFFFFF' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center' }}>
                    <Typography sx={{ color: '#FFC540', fontSize: 18 }}>Add New User</Typography>
                    <IconButton onClick={handleClose}>
                        <Close sx={{ color: '#FFFFFF', }} />
                    </IconButton>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl {...register('title')} fullWidth>
                                <TextField
                                    name='title'
                                    select
                                    label="Salutation"
                                    fullWidth
                                    value={salutation}
                                    onChange={(e) => setSalutation(e.target.value)}
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                        '& svg': {
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                    <MenuItem value="mr">Mr</MenuItem>
                                    <MenuItem value="mrs">Mrs</MenuItem>
                                    <MenuItem value="ms">Ms</MenuItem>
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl {...register('first_name')} fullWidth>
                                <TextField
                                    name='first_name'
                                    label="First Name"
                                    fullWidth
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            (firstName.trim() !== '' && <InputAdornment position="end">
                                                <CheckCircle sx={{ color: '#009621' }} />
                                            </InputAdornment>)
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl {...register('last_name')} fullWidth>
                                <TextField
                                    name='last_name'
                                    label="Last Name"
                                    fullWidth
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            (lastName.trim() !== '' && <InputAdornment position="end">
                                                <CheckCircle sx={{ color: '#009621' }} />
                                            </InputAdornment>)
                                        ),
                                    }}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                    }} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl {...register('email')} fullWidth>
                                <TextField
                                    name='email'
                                    label="Email Address"
                                    fullWidth
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        if (emailError) {
                                            setEmailError(null);
                                        }
                                    }}
                                    onBlur={(e) => { if (e.target.value.length > 0) { validateEmail(e.target.value) } }}
                                    error={emailError}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            ((emailError !== null && email !== '') && <InputAdornment position="end">
                                                {emailError ? (
                                                    <Error color='error' />
                                                ) : (
                                                    <CheckCircle sx={{ color: '#009621' }} />
                                                )}
                                            </InputAdornment>)
                                        ),
                                    }}
                                    helperText={
                                        emailError && 'Invalid Email. It should be in the format example@domain.com'
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                    }} /></FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl {...register('role')} fullWidth>
                                <TextField
                                    name='role'
                                    select
                                    label="Role"
                                    value={role}
                                    onChange={(e) => setRole(e.target.value)}
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                        '& svg': {
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                    {roles.map((r) => (
                                        ((!admin && r.value != 'admin') || admin) &&
                                        <MenuItem key={r.label} value={r.value}>
                                            {r.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        {/*                         <Grid item xs={4}>
                            <FormControl  fullWidth>
                                <TextField
                                    name='country'
                                    select
                                    label="Country"
                                    value={country}
                                    onChange={(e) => setCountry(e.target.value)}
                                    required
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        }, '& svg': {
                                            color: '#FFFFFF'
                                        }
                                    }}>
                                    {country_codes.map((c) => (
                                        <MenuItem key={c} value={c}>
                                            {c}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid> */}
                        <Grid item xs={8}>
                            <FormControl {...register('phone_number')} fullWidth>
                                <TextField
                                    name='phone_number'
                                    label="Phone Number"
                                    fullWidth
                                    value={phoneNumber}
                                    onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                        if (phoneNumberError) {
                                            setPhoneNumberError(null);
                                        }
                                    }}
                                    required
                                    onBlur={(e) => { if (e.target.value.length > 0) { validatePhoneNumber(e.target.value) } }}
                                    error={phoneNumberError}
                                    InputProps={{
                                        endAdornment: (
                                            ((phoneNumberError !== null && phoneNumber !== '') && <InputAdornment position="end">
                                                {phoneNumberError ? (
                                                    <Error color='error' />
                                                ) : (
                                                    <CheckCircle sx={{ color: '#009621' }} />
                                                )}
                                            </InputAdornment>)
                                        ),
                                    }}
                                    helperText={
                                        phoneNumberError && 'Invalid Phone. It should be 10 digits'
                                    }
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                                borderRadius: '8px',
                                                borderColor: '#C7CCD0',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: '#C7CCD0',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: '#C7CCD0',
                                            }
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            //fontFamily: 'Poppins', 
                                            //fontWeight: 400, 
                                            //lineHeight: '33px', 
                                            //fontSize: '22px', 
                                            color: '#FFFFFF',
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: 'rgba(255, 255, 255, 0.5)',
                                            '&.Mui-focused': {
                                                color: '#FFC540'
                                            },
                                        },
                                    }} /></FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl sx={{ mt: 2 }}>
                                <FormLabel component="legend" sx={{
                                    color: 'rgba(255, 255, 255, 0.5)',
                                    '&.Mui-focused': {
                                        color: '#FFC540'
                                    }
                                }}>Job Type</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label='job-type'
                                    name='job-type'
                                    value={jobType}
                                    onChange={(e) => setJobType(e.target.value)}>
                                    {jobTypes.map((j) => (
                                        <FormControlLabel
                                            {...register('job_type')}
                                            name='job_type'
                                            key={j.label}
                                            value={j.value}
                                            control={<Radio sx={{
                                                color: '#C7CCD0',
                                                '&.Mui-checked': {
                                                    color: '#FFC540'
                                                },
                                            }} />}
                                            label={j.label}
                                        />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        {/* Clear Button */}
                        <StyledButtonContained variant='contained'
                            onClick={handleClear}
                            sx={{
                                width: '180px',
                                height: '60px',
                                cursor: 'pointer'
                            }}>
                            Clear
                        </StyledButtonContained>
                        {/* Submit Button */}
                        <StyledButtonContained variant="contained"
                            type='submit'
                            disabled={(phoneNumberError || phoneNumber === '' || emailError || email === '' || salutation === '' ||
                                firstName === '' || lastName === '' || role === '' || jobType === '')}
                            sx={{
                                width: '180px',
                                height: '60px'
                            }}>
                            Submit
                        </StyledButtonContained>
                    </Box>
                </form>
            </DialogContent>
        </Dialog>
    </>
    )
}