import React, { useState } from "react";
import {
  Stack,
  Toolbar,
  Tooltip,
  Grid,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { TAPES_ARRAY } from "../../../../../utils/constants";
import { TapeDetails } from "../../../../TapeDetails/tapeDetails";


const CommonTable = ({ restoreData, startIndex, endIndex, tapeCount, handlePageChange, totalPages, currentPage, classes }) => {
  const [selectedData, setSelectedData] = React.useState();
  const [openTapeDetails, setOpenTapeDetails] = React.useState(false);
  const getTapeImage = (media_type) => {
    let _data = TAPES_ARRAY.filter(e => e.fileName.includes(media_type));
    return _data.length && _data[0].filePath
  }
  const selectDetail = (row) => {
    setSelectedData(row.item_id);
    setOpenTapeDetails(true);
  }
  return (
    <>
      <TapeDetails open={openTapeDetails} closePopup={() => { setOpenTapeDetails(false) }} tapeId={selectedData} fromCompany={true}></TapeDetails>

      <div className="auditTable">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Tape Volser
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Tape type
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Tape format
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Data Volume
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Retention Date
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Created Date
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Comments
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Tape Photgrapgh
                </Typography>
              </TableCell>
              <TableCell className={classes.columnHeader}>
                <Typography
                  sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}
                >
                  Status
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            sx={{
              "& > *": {
                backgroundColor: "#3A3E3F",
                borderRadius: "8px",
              },
            }}
          >
            {restoreData.length > 0 ? restoreData
              // .slice(0, 8)
              .map((row, index) => (
                <TableRow
                  key={row.item_id}
                  sx={{
                    "& > *": {
                      border: "unset",
                    },
                  }}
                >
                  <TableCell>
                    <Typography
                      className="restore-name"
                      key={row.item_id}
                      id={`restore-name-${index}`}
                      sx={{
                        color: "#FFFFFF",
                        fontSize: 14,
                        display: "flex",
                        alignItems: "center",
                        fontFamily: "Poppins",
                        fontWeight: row.selected ? 700 : 400,
                        whiteSpace: "pre",
                      }}
                    >
                      {row.item_volser}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row?.media_type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.backup_format}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.size}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.createdOn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.createdOn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.createdOn}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      <img onClick={() => {
                        selectDetail(row)
                      }} style={{ height: '64px' }} src={getTapeImage(row.media_type)} />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: row.selected ? 700 : 400,
                      }}
                    >
                      {row.status}
                    </Typography>
                  </TableCell>
                </TableRow>
              )) : <div style={{ textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center" }}>Job Id not Found</div>}
          </TableBody>
        </Table>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            padding={2}
            style={{
              width: "100%",
              border: "1px solid #C7CCD0",
              minWidth: "55%",
            }}
          >
            {/* <Grid item ml={2}>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    color: "#FFFFFF",
                  }}
                >
                  {`Showing 1-10 of 100 files`}
                </Typography>
              </Grid> */}
            <Grid item ml={2}>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 400,
                  color: "#FFFFFF",
                }}
              >
                {`Showing ${tapeCount ? startIndex : 0}-${endIndex} of ${tapeCount} Items`}
              </Typography>
            </Grid>
            <Grid item>
              <Pagination
                component="div"
                rowsPerPage={10}
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    width: "30px",
                    height: "30px",
                    border: "1px solid #FFFFFF",
                    background: "#3A3E3F",
                    color: "#FFFFFF",
                    margin: "0 4px",
                    "&.Mui-selected": {
                      background: "#FFC540",
                      color: "#3A3E3F",
                    },
                  },
                  "& .MuiPaginationItem-page.Mui-selected:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-page:hover": {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                  "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                    width: "100px",
                    height: "40px",
                    borderRadius: "8px",
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    background: "#3A3E3F",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  },
                  "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover":
                  {
                    background: "#FFC540",
                    color: "#3A3E3F",
                  },
                }}
                renderItem={(item) => <PaginationItem {...item} />}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default CommonTable