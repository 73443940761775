import { useEffect, useState } from "react";
import {
    Grid, Paper, Box, Typography, Button, IconButton, TableContainer, Container,
    TableHead, TableRow, TableCell, TableBody, Table, PaginationItem, Toolbar, InputBase,
    InputAdornment, Pagination, tableCellClasses, TableFooter, Checkbox, TableSortLabel, Tooltip, FormControl, InputLabel, Select, MenuItem, TextField, Chip, Stack, Dialog, DialogContent, Snackbar
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AddOutlined, Cancel, Delete, Search } from "@mui/icons-material";
import { styled } from "@mui/styles";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider } from "@mui/material";
import { AdminFeeCreate } from "./adminFeeCreate/adminFeeCreate";
import { visuallyHidden } from '@mui/utils';
//import { env } from "../../../env";
import axios from "axios";
import { FEE_SERVICE, FEE_SERVICE_DURATION } from "../../../utils/constants";
import { getApiUrl } from "../../../global";
import { flexStyles } from "../../theme/theme";
import { StyledButtonContained } from "../../../views/Admin/AccountCreation/accountCreation";

const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

const useStyles = makeStyles({
    highlight: {
        background: '#FFC540',
        color: '#3A3E3F',
    },
});

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#3A3E3F',
        color: '#F9F9F9',
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&.MuiTableRow-root': {
        '&:nth-of-type(odd)': {
            background: 'rgba(95,101,102,0.3)',
        },
        '&:nth-of-type(even)': {
            background: '#3A3E3F',
        },
        '&.Mui-selected': {
            //background: 'rgba(255, 197, 64, 0.1)',
            '&:hover': {
                '&:nth-of-type(odd)': {
                    background: 'rgba(95,101,102,0.3)',
                },
                '&:nth-of-type(even)': {
                    background: '#3A3E3F',
                },
            },
        },
        '& > *': {
            color: '#FFFFFF',
            border: 'unset',
        }
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const adminFeeTheme = createTheme({
    palette: {
        primary: {
            main: '#FFC540',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
    typography: {
        fontWeightRegular: 400,
        fontWeightBold: 700,
        caption: {
            fontFamily: 'Poppins',
            fontSize: '12px',
            lineHeight: '14.5px',
            color: '#B4B4B4',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                containedPrimary: {
                    background: 'linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)',
                    color: '#3A3E3F',
                    '&.Mui-disabled': {
                        background: '#C7CCD0',
                        color: '#5F5F5F'
                    },
                },
                textPrimary: {
                    color: '#FFC540',
                },
                containedSecondary: {
                    background: '#3A3E3F',
                    color: '#FFC540',
                    border: '1px solid #FFC540',
                    '&:hover': {
                        backgroundColor: 'rgba(255, 197, 64, 0.1)',
                    },
                },
                outlinedSecondary: {
                    border: '1px solid #FFFFFF',
                    color: '#FFFFFF',
                },
                containedError: {
                    background: '#FF6C65',
                    color: '#3A3E3F',
                }
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: '#FFC540',
                },
                colorSecondary: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#FFFFFF',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '&.outlined-input': {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '8px',
                                borderColor: '#C7CCD0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C7CCD0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#C7CCD0',
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            //fontFamily: 'Inter', 
                            color: '#FFFFFF',
                        },
                        '& .MuiInputLabel-root': {
                            //fontFamily: 'Inter', 
                            color: 'rgba(255, 255, 255, 0.5)',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                        '& svg': {
                            color: '#FFFFFF'
                        }
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '&.outlined-input': {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderRadius: '8px',
                                borderColor: '#C7CCD0',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C7CCD0',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#C7CCD0',
                            }
                        },
                        '& .MuiOutlinedInput-input': {
                            //fontFamily: 'Inter', 
                            color: '#FFFFFF',
                        },
                        '& .MuiInputLabel-root': {
                            //fontFamily: 'Inter', 
                            color: 'rgba(255, 255, 255, 0.5)',
                            '&.Mui-focused': {
                                color: '#FFC540'
                            },
                        },
                        '& svg': {
                            color: '#FFFFFF'
                        }
                    },
                }
            }
        }

    },
});

{/* Container component for Fee step */ }
export const AdminFee = (props) => {
    let _orderUUID = window?.location?.href?.split("uuid=")[1];

    // Determines whether to show Fee landing page or Fee List
    const [stepVisited, setStepVisited] = useState(true);

    const [currency, setCurrency] = useState('AUD');

    //Fee List

    const [feeData, setFeeData] = useState([
        //{ uuid: '1', service: 'account_creation', service_duration: 'free', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
        //{ uuid: '2', service: 'Account Setup', service_duration: 'One Off', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
        //{ uuid: '3', service: 'Physical Tape Storage / Per Tape', service_duration: 'Monthly', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
        //{ uuid: '4', service: 'Project and Environment Setup', service_duration: 'Per Consumtion, Billed at end of month', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
        //{ uuid: '5', service: 'Consulting (Project services)', service_duration: 'Per Consumtion, Billed at end of month', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
        //{ uuid: '6', service: 'Local Freight', service_duration: 'Transactional (Cost + X%)', service_rate: '$2.40 + 15%', milestoneDueDate: "End Of Month", payment: '$2.40', selected: false },
    ]);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [feeCount, setFeeCount] = useState(0);

    const fetchFees = async () => {
        let url = await getApiUrl();

        const feeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + "/fees/" + `?page=${currentPage}`;
        axios.get(feeApiUrl)
            .then(data => {
                setFeeData(data.data.results);
                setFeeCount(data.data.count);
                setTotalPages(Math.ceil(data.data.count / 10));

                if (!(data?.data?.count)) {
                    setStepVisited(false);
                    props.setDisableSaveAndNext(true)
                } else {
                    setStepVisited(true);
                    props.setDisableSaveAndNext(false)
                }
            })
            .catch(error => {
                console.log(error)
            });
    };

    const [deleteLastFeeConfirmationOpen, setDeleteLastFeeConfirmation] = useState(false);
    const [deleteLastFeeId, setDeleteLastFeeId] = useState(null);

    const onDeleteLastFeeClose = (uuid) => {
        setDeleteLastFeeId(null);
        setDeleteLastFeeConfirmation(false);
    };

    const deleteMultipleFees = () => {
        // debugger;
        selected.forEach((row) => {
            deleteFees(row);
        });
        setSelected([]);
    };

    const [state, setState] = useState({
        openSb: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const { vertical, horizontal, openSb } = state;

    const openSnackbar = (message) => {
        setSnackbarMessage(message)
        setState({
            vertical: 'top', horizontal: 'right', openSb: true
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: 'top', horizontal: 'right', openSb: false
        });
    };

    const deleteFees = async (uuid) => {
        let url = await getApiUrl();

        const feeApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/orders/" + _orderUUID + `/fees/${uuid}/`;
        axios.delete(feeApiUrl)
            .then((res) => {
                if (res?.status === 204) {
                    openSnackbar('The Fee has been deleted successfully.');
                }
                fetchFees();
            })
            .catch(error => {
                console.log(error)
            });
    }

    useEffect(() => {
        fetchFees();
    }, [currentPage]);

    const [addedRows, setAddedRows] = useState([]);
    const handleAddRow = (row) => {
        const newFeeData = [...feeData];
        newFeeData.find(newDataRow => newDataRow.uuid === row.uuid).selected = true;
        setFeeData(newFeeData);

        setAddedRows([...addedRows, row]);
    };
    const handleRemoveRow = (id) => {
        const newFeeData = [...feeData];
        newFeeData.find(row => row.uuid === id).selected = false;
        setFeeData(newFeeData);

        const updateAddedRows = addedRows.filter((row) => row.uuid !== id);
        setAddedRows(updateAddedRows);
    }

    const clearAddedRows = () => {
        const newFeeData = [...feeData];
        newFeeData.forEach(row => row.selected = false);
        setFeeData(newFeeData);

        setAddedRows([]);
    }

    const classes = useStyles();
    const [sortBy, setSortBy] = useState('columnName');
    const [sortOrder, setSortOrder] = useState('asc');

    const [editingItem, setEditingItem] = useState(null);

    const [open, setOpen] = useState(false);
    // To open the dialog
    const handleOpenDialog = (item) => {
        if (item) {
            setEditingItem(item);
        }
        setOpen(true);
    };
    // To close the dialog
    const handleCloseDialog = () => {
        setOpen(false);
        setEditingItem(null);
        fetchFees();
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        }
        else {
            setSortBy(column);
            setSortOrder('asc');
        }
    };

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchQuery = (event) => {
        setSearchQuery(event.target.value);
    }

    const highlightText = (id, text) => {
        if (!searchQuery || searchQuery === '') {
            return text;
        };
        const regex = new RegExp(`(${searchQuery})`, 'gi');
        const parts = text.split(regex);
        return parts.map((part, index) =>
            (part.toLowerCase() === searchQuery.toLowerCase()) ? (
                <Typography sx={{ fontSize: 14 }} className={classes.highlight}>{part}</Typography>
            ) : (
                <Typography sx={{ fontSize: 14 }} >{part}</Typography>
            )
        );
    }

    //const [order, setOrder] = useState('asc');
    //const [orderBy, setOrderBy] = useState('service');
    const [selected, setSelected] = useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = feeData.map((n) => n.uuid);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };



    const isSelected = (id) => selected.indexOf(id) !== -1;


    return (
        <ThemeProvider theme={adminFeeTheme}>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSb}
                onClose={closeSnackbar}
                message={snackbarMessage}
                key={vertical + horizontal}
            />
            <Box sx={{
                minHeight: '100vh',
                height: '100%',
                border: '1px solid #C7CCD0',
                borderRadius: '8px',
                mt: 2,
                mb: '100px',
            }}>
                {/* Step Banner */}
                <Box
                    px={4}
                    width={'inherit'}
                    height={'4rem'}
                    bgcolor={'#272727'}
                    borderRadius={'8px 8px 0px 0px'}
                    display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant="h5" sx={{ color: '#FFFFFF' }} fontFamily={'Poppins'}>
                        Fee
                    </Typography>
                    <Stack direction={'row'} gap={4}>
                        {/* <FormControl sx={{mt: '5px'}}>
                            <InputLabel
                                shrink={false}
                                sx={{ color: '#FFFFFF', fontSize: '14px', fontWeight: 400, fontFamily: 'Poppins', }}>
                                Currency :
                            </InputLabel>
                            <TextField
                                select
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        width: '10ch',
                                        '& fieldset': {
                                            border: 'unset',
                                        },
                                        'label + &': {
                                            marginLeft: '10ch',
                                        },
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        fontFamily: 'Poppins',
                                        fontSize: '20px',
                                        fontWeight: 700,
                                        color: '#FFFFFF',
                                    },
                                    '& svg': {
                                        color: '#FFFFFF'
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                value={currency}
                                onChange={(e) => setCurrency(e.target.value)}>
                        <MenuItem value={'AUD'} key={'AUD'}>AUD</MenuItem>
                        <MenuItem value={'GBP'} key={'GBP'}>GBP</MenuItem>
                        <MenuItem value={'USD'} key={'USD'}>USD</MenuItem>
                        <MenuItem value={'NZD'} key={'NZD'}>NZD</MenuItem>
                        <MenuItem value={'INR'} key={'INR'}>INR</MenuItem>
                        <MenuItem value={'MYR'} key={'MYR'}>MYR</MenuItem>
                    </TextField>
                </FormControl> */}
                        {feeData?.length ? <Button type="button" sx={{ fontFamily: 'Poppins', cursor: 'pointer' }} onClick={() => { handleOpenDialog(); }}>+ Add New Fee</Button> : ""}
                    </Stack>

                </Box>

                {
                    (stepVisited) ? (
                        <>
                            <Grid container px={8}>
                                <Grid item xs={12}>
                                    <TableContainer sx={{ width: 'inherit', maxHeight: '40rem', overflowY: 'auto', mt: 4, border: '1px solid #C7CCD0', borderRadius: '12px 12px 0px 0px' }}>
                                        <Toolbar sx={{ flexGrow: 1, my: 1, }}>
                                            <Grid container spacing={4}>
                                                <Grid item xs={4}>
                                                    {selected.length > 0 ? (
                                                        <Typography
                                                            sx={{ flex: '1 1 100%' }}
                                                            fontFamily='Poppins'
                                                            textAlign='left'
                                                            color='secondary'
                                                            variant="h6"
                                                            component="div"
                                                        >
                                                            {selected.length} selected
                                                        </Typography>
                                                    ) : (
                                                        <Typography
                                                            sx={{ flex: '1 1 100%' }}
                                                            fontFamily='Poppins'
                                                            textAlign='left'
                                                            color='secondary'
                                                            variant="h6"
                                                            id="tableTitle"
                                                        >
                                                            Fee and Expenses
                                                        </Typography>
                                                    )}
                                                </Grid>
                                                <Grid item xs={2} sx={{ display: 'flex', direction: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                                                    {selected.length > 0 && (<Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', color: '#FFFFFF', }}>Bulk Actions: </Typography>)}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {selected.length > 0 && (
                                                        <Button
                                                            variant='outlined'
                                                            color='secondary'
                                                            onClick={() => {
                                                                setSelected([]);
                                                            }}
                                                            sx={{
                                                                borderRadius: '8px',
                                                                cursor: 'pointer'
                                                            }}>Cancel</Button>
                                                    )}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {selected.length > 0 && (
                                                        <Button
                                                            variant='contained'
                                                            color='error'
                                                            onClick={() => {
                                                                deleteMultipleFees();
                                                            }}
                                                            sx={{
                                                                borderRadius: '8px',
                                                                cursor: 'pointer'
                                                            }}>Delete</Button>
                                                    )}
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <InputBase
                                                        id="search-fees"
                                                        className="search-fees"
                                                        type="text"
                                                        placeholder="Search"
                                                        value={searchQuery}
                                                        onChange={handleSearchQuery}
                                                        startAdornment={
                                                            <InputAdornment position="start" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                                                <Search />
                                                            </InputAdornment>
                                                        }
                                                        endAdornment={
                                                            (searchQuery !== '') && <InputAdornment position="end" sx={{ color: '#A6AEC1', height: 24, width: 24 }} >
                                                                <IconButton onClick={() => { setSearchQuery(''); }} ><Cancel sx={{
                                                                    color: '#FFFFFF',
                                                                    cursor: 'pointer'
                                                                }} /></IconButton>
                                                            </InputAdornment>
                                                        }
                                                        sx={{
                                                            fontSize: '16px',
                                                            fontWeight: '400',
                                                            lineHeight: '15px',
                                                            letterSpacing: '0em',
                                                            textAlign: 'left',
                                                            backgroundColor: '#3A3E3F',
                                                            borderRadius: '4px',
                                                            height: '40px',
                                                            width: '100%',
                                                            px: '20px',
                                                            border: '1px solid #C7CCD0',
                                                            outline: 'none',
                                                            color: '#FFFFFF',
                                                        }}
                                                    />

                                                </Grid>
                                            </Grid>
                                        </Toolbar>

                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            color="primary"
                                                            indeterminate={selected.length > 0 && selected.length < feeData.length}
                                                            checked={feeData.length > 0 && selected.length === feeData.length}
                                                            onChange={handleSelectAllClick}
                                                            inputProps={{
                                                                'aria-label': 'select all desserts',
                                                            }}
                                                        />
                                                    </TableCell>
                                                    {headCells.map((headCell) => (
                                                        <TableCell
                                                            key={headCell.id}
                                                            align={headCell.numeric ? 'right' : 'left'}
                                                            padding={headCell.disablePadding ? 'none' : 'normal'}
                                                            //sortDirection={orderBy === headCell.id ? order : false}
                                                            sx={{ color: '#FFFFFF', fontSize: 14 }}
                                                        >
                                                            {headCell.label}
                                                            {/*                                             <TableSortLabel
                                                                active={orderBy === headCell.id}
                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                //onClick={createSortHandler(headCell.id)}
                                                            >
                                                                {headCell.label}
                                                                {orderBy === headCell.id ? (
                                                                    <Box component="span" sx={visuallyHidden}>
                                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                    </Box>
                                                                ) : null}
                                                            </TableSortLabel> */}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell>
                                                        {/* Actions */}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody sx={{
                                                '& > *': {
                                                    borderRadius: '8px'
                                                }
                                            }}>
                                                {feeData.map((row, index) => {

                                                    const isItemSelected = isSelected(row.uuid);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <StyledTableRow
                                                            key={row.uuid}
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            selected={isItemSelected}>

                                                            <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{ cursor: 'pointer' }}
                                                                padding="checkbox">
                                                                <Checkbox
                                                                    color="primary"
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{ cursor: 'pointer', minWidth: '10rem' }}
                                                                component="th"
                                                                id={labelId}
                                                                scope="row"
                                                                padding="none">
                                                                <Typography className='fee-name' key={index} id={`fee-name-${index}`} sx={{
                                                                    color: '#FFFFFF', fontSize: 14, display: 'flex',
                                                                    alignItems: 'center', fontFamily: 'Poppins', whiteSpace: 'pre',
                                                                }}>
                                                                    {FEE_SERVICE.find((service) => service.key === row.service)?.service}{/*highlightText(row.uuid, row.service)*/}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{ cursor: 'pointer' }} >
                                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                                    <Chip
                                                                        sx={{
                                                                            border: '1px solid #FFFFFF',
                                                                            color: '#FFFFFF',
                                                                            backgroundColor: '#3A3E3F'
                                                                        }}
                                                                        label={FEE_SERVICE_DURATION.find((interval) => interval.key === row.service_duration)?.label}></Chip>
                                                                </Typography>
                                                            </TableCell>
                                                            {/* <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{ cursor: 'pointer' }} >
                                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                                    <Chip
                                                                        sx={{
                                                                            border: '1px solid #FFFFFF',
                                                                            color: '#FFFFFF',
                                                                            backgroundColor: '#3A3E3F'
                                                                        }}
                                                                        label={row.service_rate}></Chip>
                                                                </Typography>
                                                            </TableCell> */}
                                                            {/*                                                             <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{cursor: 'pointer'}} >
                                                                <Typography sx={{ color: '#FFFFFF', fontFamily: 'Poppins', fontSize: 14, }}>
                                                                    {row.milestoneDueDate}
                                                                </Typography>
                                                            </TableCell> */}
                                                            <TableCell
                                                                onClick={(event) => handleClick(event, row.uuid)}
                                                                sx={{ cursor: 'pointer' }} >
                                                                <Typography sx={{ color: '#FFFFFF', maxWidth: '8rem', overflowX: 'auto', fontFamily: 'Poppins', fontSize: 22, }}>
                                                                    {row.service_fee}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    variant='outlined'
                                                                    color='secondary'
                                                                    onClick={() => { handleOpenDialog(row); }}
                                                                    sx={{
                                                                        borderRadius: '8px',
                                                                        mr: 4,
                                                                        cursor: 'pointer'
                                                                    }}>Edit</Button>
                                                                <Button
                                                                    variant='contained'
                                                                    color='error'
                                                                    onClick={() => {
                                                                        if (feeData.length === 1) {
                                                                            setDeleteLastFeeId(row.uuid);
                                                                            setDeleteLastFeeConfirmation(true);
                                                                        } else {
                                                                            deleteFees(row.uuid);
                                                                        }
                                                                    }}
                                                                    hidden
                                                                    sx={{
                                                                        borderRadius: '8px',
                                                                        visibility: (selected.length > 0) ? 'hidden' : 'visible',
                                                                        cursor: 'pointer'
                                                                    }}>Delete</Button>
                                                            </TableCell>

                                                        </StyledTableRow>
                                                    )
                                                })}
                                            </TableBody>

                                            {/*                             <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan={7}>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter> */}
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container
                                        direction="row"
                                        justifyContent="space-between"
                                        padding={2}
                                        style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '55%' }}>
                                        <Grid item ml={2}>
                                            {/*                                         <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                                            {`Showing ${startIndex}-${endIndex} of ${feeCount} Fees`}
                                        </Typography> */}
                                        </Grid>
                                        <Grid item>
                                            <Pagination
                                                component="div"
                                                rowsPerPage={10}
                                                count={totalPages}
                                                page={currentPage}
                                                onChange={handlePageChange}
                                                sx={{
                                                    '& .MuiPaginationItem-root': {
                                                        width: '30px',
                                                        height: '30px',
                                                        border: '1px solid #FFFFFF',
                                                        background: '#3A3E3F',
                                                        color: '#FFFFFF',
                                                        margin: '0 4px',
                                                        '&.Mui-selected': {
                                                            background: '#FFC540',
                                                            color: '#3A3E3F',
                                                        },
                                                    },
                                                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                                                        background: '#FFC540',
                                                        color: '#3A3E3F',
                                                    },
                                                    '& .MuiPaginationItem-page:hover': {
                                                        background: '#FFC540',
                                                        color: '#3A3E3F',
                                                    },
                                                    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                                        width: '100px',
                                                        height: '40px',
                                                        borderRadius: '8px',
                                                        border: '1px solid #FFFFFF',
                                                        color: '#FFFFFF',
                                                        background: '#3A3E3F',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    },
                                                    '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                                        background: '#FFC540',
                                                        color: '#3A3E3F',
                                                    },
                                                }}

                                                renderItem={(item) => (
                                                    <PaginationItem {...item} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Dialog open={deleteLastFeeConfirmationOpen} onClose={onDeleteLastFeeClose} fullWidth={true} PaperProps={{ sx: { borderRadius: "12px", backgroundColor: '#3A3E3F' } }}>
                                    <DialogContent>
                                        <Grid container direction='column' spacing={2}>
                                            <Grid item>
                                                <Typography style={{ fontWeight: 600, fontSize: '24px', lineHeight: '36px', color: '#FFC540' }}>Are you sure?</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography style={{ color: '#FFFFFF', fontFamily: 'Poppins' }}>You are about to delete the only fee for this order.</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction='row' justifyContent='space-between'>
                                                    <Grid item>
                                                        <Button variant='outlined' onClick={onDeleteLastFeeClose}
                                                            sx={{
                                                                width: '200px',
                                                                height: '60px',
                                                                borderRadius: '30px',
                                                                color: '#FFC540',
                                                                textTransform: 'none',
                                                                border: '1px solid #FFC540',
                                                                '&:hover': {
                                                                    border: '1px solid #FFC540',
                                                                },
                                                                cursor: 'pointer'
                                                            }}
                                                        >Cancel</Button>
                                                    </Grid>
                                                    <StyledButtonContained variant='contained'
                                                        onClick={() => {
                                                            deleteFees(deleteLastFeeId); setDeleteLastFeeConfirmation(false);
                                                        }}
                                                        sx={{
                                                            width: '200px',
                                                            height: '60px',
                                                            borderRadius: '30px',
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        Proceed
                                                    </StyledButtonContained>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        </>
                    ) : (
                        <>
                            {/* Landing Page Phase 2 */}
                            <Grid container spacing={4} mt={8} px={42}>
                                <Grid item xs={12}>
                                    <Typography sx={{
                                        color: '#FFFFFF',
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        fontSize: '20px'
                                    }}>How do you want to proceed?</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Create New */}
                                    <StyledButtonContained
                                        variant='contained'
                                        sx={{
                                            height: '4rem', width: '12rem',
                                            cursor: 'pointer'
                                        }}
                                        color='primary'
                                        onClick={() => { handleOpenDialog(); }}>Create New</StyledButtonContained>
                                </Grid>
                                <Grid item xs={6}>
                                    {/* Add Existing */}
                                    <StyledButtonContained
                                        variant='contained'
                                        sx={{
                                            height: '4rem', width: '12rem',
                                            cursor: 'pointer'
                                        }}
                                        color='primary'
                                        disabled
                                        onClick={() => setStepVisited(true)}>Choose Existing</StyledButtonContained>
                                </Grid>

                            </Grid>
                        </>
                    )
                }
                <AdminFeeCreate orderId={props.orderId} item={editingItem} open={open} onClose={handleCloseDialog}></AdminFeeCreate>
            </Box >
        </ThemeProvider >
    );
};

const headCells = [
    {
        id: 'service',
        numeric: false,
        disablePadding: true,
        label: 'Service',
    },
    {
        id: 'service_duration',
        numeric: false,
        disablePadding: false,
        label: 'Service Frequency',
    },
    // {
    //     id: 'service_rate',
    //     numeric: false,
    //     disablePadding: false,
    //     label: 'Service Rate',
    // },
    /*     {
            id: 'milestoneDueDate',
            numeric: false,
            disablePadding: false,
            label: 'Milestone Due Date',
        }, */
    {
        id: 'payment',
        numeric: false,
        disablePadding: false,
        label: 'Payment Amount',
    },
];
