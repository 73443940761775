export const FEE_SERVICE_DURATION = [
    { key: 'free', label: 'Free' },
    { key: 'one_off', label: 'One Off' },
    { key: 'consumption', label: 'Per Usage/Unit/Consumption' },
    { key: 'recurring', label: 'Recurring' },
    { key: 'transactional', label: 'Transactional' },
];

export const FEE_UNITS = [
    { key: 'tape', label: 'Per Tape' },
    { key: 'vtl', label: 'Per VTL' },
    { key: 'site', label: 'Per Site' },
    { key: 'event', label: 'Per Event' },
    { key: 'box', label: 'Per Box' },
    { key: 'request', label: 'Per Request' },
    { key: 'other', label: 'Other' },
];

export const INGESTION_SITES = [
    {key : "montreal", label : "Montreal"},
    {key : "perth", label : "Perth"},
    {key : "dallas", label : "Dallas"},
    {key : "delhi", label : "Delhi"},
    {key : "exeter", label : "Exeter"},
    // {key : "los_angeles", label : "Los Angeles"},


]

export const FEE_MILESTONE = [
    { key: 'end_of_month', label: 'Billed at the End of the Month' },
    { key: 'vtl', label: 'Per VTL' },
    { key: 'monthly_ongoing', label: 'Monthly Ongoing' },
    { key: 'adhoc', label: 'As Requested' },
    { key: 'month_1_3', label: 'Month 1 to 3' },

];

//  const FEE_CURRENCY = [
//     { key: 'INR', label: 'INDIAN RUPEES' },
//     { key: 'AUD', label: 'Australian Dollar' },
// ];
export const FEE_SERVICE = [
    { key: "local_freight", service: "Local Freight", serviceFrequency: '', serviceRate: '' },
    { key: "other_sales", service: "Other Sales", serviceFrequency: '', serviceRate: '' },
    { key: "pilot_services_sales", service: "Pilot Services Sales", serviceFrequency: '', serviceRate: '' },
    { key: "tape_audit", service: "Tape Audit (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "consulting", service: "Consulting (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "destruction_sales", service: "Destruction Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "data_format_conversion", service: "Data format conversion (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "data_processing_&_analysis", service: "Data Processing & Analysis (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "data_reformatting", service: "Data Reformatting (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "data_transfer", service: "Data Transfer (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "freight", service: "Freight (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "hardware_sales", service: "Hardware Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "tape_ingest", service: "Tape Ingest (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "media_sales", service: "Media Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "setup/mobilisation", service: "Setup/Mobilisation (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "software_sales", service: "Software Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_tape_audit", service: "TCSaas Tape Audit (Initial)", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_aws_cloud_storage_mrr", service: "TCSaaS AWS Cloud Storage MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_destruction_sales", service: "TCSaaS Destruction Sales", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_data_rotation_mrr", service: "TCSaaS Data Rotation MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_freight", service: "TCSaaS Freight", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_google_cloud_storage_mrr", service: "TCSaaS Google Cloud Storage MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_ingest", service: "TCSaaS Ingest (Initial)", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_tape_ingest_mrr", service: "TCSaaS Tape Ingest MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_software_services_sales_mrr", service: "TCSaaS Software Services Sales MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_microsoft_cloud_storage_mrr", service: "TCSaaS Microsoft Cloud Storage MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_data_on_call_services_mrr", service: "TCSaaS Data on Call Services MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_other_cloud_storage_mrr", service: "TCSaaS Other Cloud Storage MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_physical_storage_mrr", service: "TCSaaS Physical Storage MRR", serviceFrequency: '', serviceRate: '' },
    { key: "tcsaas_restore_on_demand_mrr", service: "TCSaaS Restore on Demand MRR", serviceFrequency: '', serviceRate: '' },

    // { key: "legacy_hardware_removal", service: "Removal of legacy hardware", serviceFrequency: '', serviceRate: '' },
    // { key: "account_creation", service: "Account Creation", serviceFrequency: '', serviceRate: '' },
    // { key: "mobilisation", service: "Mobilisation", serviceFrequency: '', serviceRate: '' },
    // { key: "decommisioning", service: "Decommisioning", serviceFrequency: '', serviceRate: '' },
    // { key: "project_environment_setup", service: "Project and Environment Setup", serviceFrequency: '', serviceRate: '' },
    // { key: "physical_tape_storage", service: "Physical Tape Storage/ per tape", serviceFrequency: '', serviceRate: '' },
    // { key: "monthly_account_management", service: "Account Management Fee / fixed monthly fee", serviceFrequency: '', serviceRate: '' },
    // { key: "aws_vtl_setup", service: "Establishment of AWS VTL (Per VTL)", serviceFrequency: '', serviceRate: '' },
    // { key: "tape_audit", service: "Auditing of Tapes (Per Tape)", serviceFrequency: '', serviceRate: '' },
    // { key: "restore_on_demand", service: "Restore on Demand (per restore event)", serviceFrequency: '', serviceRate: '' },
    // { key: "tape_ingest", service: "Tape Ingest(Per Tape)", serviceFrequency: '', serviceRate: '' },
    // { key: "physical_storage_tape_transport", service: "Transport of Tapes from Physical Storage(per event)", serviceFrequency: '', serviceRate: '' },
    // { key: "out_of_hours_holiday_weekend", service: "Out of hours, holidays or weekend", serviceFrequency: '', serviceRate: '' },
    // { key: "urgent_restores", service: "surcharge for urgent restores (Per Request)", serviceFrequency: '', serviceRate: '' },
    // { key: "tape_retrieval", service: "Retrieval of Tapes from Physical", serviceFrequency: '', serviceRate: '' },
    // { key: "storage", service: "Storage (Per Tape)", serviceFrequency: '', serviceRate: '' },
    // { key: "storage_boxes", service: "Physical boxes for storage (Per Box)", serviceFrequency: '', serviceRate: '' },
    // { key: "confidential_tape_destruction", service: "Confidentail Destruction of Tapes (Per Tape)", serviceFrequency: '', serviceRate: '' },
    // { key: "network_data_transfer", service: "Network Data Transfer", serviceFrequency: '', serviceRate: '' },
    // { key: "data_reformatting", service: "Data Reformatting (per tape)", serviceFrequency: '', serviceRate: '' },
    // { key: "data_deletion", service: "Data Deletion", serviceFrequency: '', serviceRate: '' },
    // { key: "data_indexing", service: "Data Indexing", serviceFrequency: '', serviceRate: '' },
    // { key: "e_discovery", service: "e-Discovery", serviceFrequency: '', serviceRate: '' },
    // { key: "consulting", service: "Consulting (Project Services)", serviceFrequency: '', serviceRate: '' },
    // { key: "tape_transport", service: "Transport of Tapes (Physical shipping)", serviceFrequency: '', serviceRate: '' },
    // { key: "aws_fees", service: "AWS Cloud Storage and Processing Fees incurred on", serviceFrequency: '', serviceRate: '' },
    // { key: "tapeark_paid", service: "behalf of Customer (AWS Charges accrued in the to the subaccount)", serviceFrequency: '', serviceRate: '' },
    // { key: "international_freight", service: "International Freight", serviceFrequency: '', serviceRate: '' },
    // { key: "interstate_freight", service: "Interstate Freight", serviceFrequency: '', serviceRate: '' },
    // { key: "local_freight", service: "Local Freight", serviceFrequency: '', serviceRate: '' },
    // { key: "other_sales", service: "Other Sales", serviceFrequency: '', serviceRate: '' },
    // { key: "hardware_sales", service: "Hardware Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
    // { key: "media_sales", service: "Media Sales (Project Services)", serviceFrequency: '', serviceRate: '' },
];

export const BACKUP_FORMATS = [
    { key: "symantec_veritas_netbackup", label: 'Symantex/Veritas NetBackup' },
    { key: "commvault_simpana", label: 'Commvault Simpana' },
    { key: "commvault_galaxy", label: 'Commvault Galaxy' },
    { key: "symantec_veritas_backup_exec", label: 'Symantec/Veritas Bacup Exec' },
    { key: "ibm_spectrumprotect", label: 'INM SpectrumProtect' },
    { key: "seagate_backup", label: 'Seagate Backup' },
    { key: "tar", label: 'TAR' },
    { key: "iomega_ditto", label: 'Iomega Ditto' },
    { key: "hp_colorado_backup", label: 'HP Colorado Backup' },
    { key: "tapeware", label: 'TapeWare' },
    { key: "ultraback", label: 'Ultraback' },
    { key: "emc", label: 'EMC' },
    { key: "veeam", label: 'VEEAM' },
    { key: "catalogic_dfpx", label: 'Catalogic DFPX' },
    { key: "hp_omniback_data_protector", label: 'HP/Omniback Data Protector' },
    { key: "ca_brightstor_arcserve", label: 'CA/Brightstor Arcserve' },
    { key: "quest_bakbone_netvault", label: 'Quest/Bakbone Netvault' },
    { key: "emc_legato_networker", label: 'EMX/Legato Networker' },
    { key: "avamar", label: 'Avamar' },
    { key: "data_domain", label: 'Data Domain' },
    { key: "tivoli_storage_manager", label: 'Tivoli Storage Manager' },
    { key: "microsoft_tape_format", label: 'Microsoft Tape Format' },
    { key: "nt_backup", label: 'NT Backup' },
    { key: "cheyenne", label: 'Cheyenne' },
    { key: "arcada", label: 'Arcada' },
    { key: "retrospect", label: 'Retrospect' },
    { key: "unix_tar", label: 'Unix TAR' },
    { key: "cpio", label: 'CPIO' },
    { key: "fbackup", label: 'FBackup' },
    { key: "fsdump", label: 'FSDump' },
    { key: "ufsdump", label: 'UFSDump' },
    { key: "compaq", label: 'Compaq' },
    { key: "vms_backup", label: 'VMS Backup' },
    { key: "intelliguard", label: 'Intelliguard' },
    { key: "sytron", label: 'Sytron' },

];


export const MEDIA_TYPES = [
    { key: "lto", label: "LTO", type: "subheader" },
    { key: "lto1", label: "LTO-1", type: "value" },
    { key: "lto2", label: "LTO-2", type: "value" },
    { key: "lto3", label: "LTO-3", type: "value" },
    { key: "lto4", label: "LTO-4", type: "value" },
    { key: "lto5", label: "LTO-5", type: "value" },
    { key: "lto6", label: "LTO-6", type: "value" },
    { key: "lto7", label: "LTO-7", type: "value" },
    { key: "ltom8", label: "LTO M8", type: "value" },
    { key: "lto8", label: "LTO-8", type: "value" },
    { key: "lto9", label: "LTO-9", type: "value" },

    { key: "t10000", label: "T10000", type: "subheader" },
    { key: "t10000a", label: "T10000A", type: "value" },
    { key: "t10000b", label: "T10000B", type: "value" },
    { key: "t10000c", label: "T10000C", type: "value" },
    { key: "t10000d", label: "T10000D", type: "value" },

    { key: "3592", label: "3592", type: "subheader" },
    { key: "t3592j1a", label: "T3592-J1A (3592)", type: "value" },
    { key: "t3592e05", label: "T3592-E05 (TS1120)", type: "value" },
    { key: "t3592e06", label: "T3592-E06 (TS1130)", type: "value" },
    { key: "t3592e07", label: "T3592-E07 (TS1140)", type: "value" },
    { key: "t3592e08", label: "T3592-E08 (TS1150)", type: "value" },
    { key: "t359255f", label: "T3592-55F (TS1155)", type: "value" },

    { key: "hdd", label: "HDD", type: "value" },
    { key: "server", label: "Server", type: "value" },
    { key: "appliance", label: "Appliance", type: "value" },
    { key: "dvd", label: "DVD", type: "value" },
    { key: "cd", label: "CD", type: "value" },
    { key: "blueray", label: "BlueRay", type: "value" },

    { key: "4mmdat", label: "4mm DAT", type: "subheader" },

    { key: "dds1", label: "DDS-1", type: "value" },
    { key: "dds2", label: "DDS-2", type: "value" },
    { key: "dds3", label: "DDS-3", type: "value" },
    { key: "dds4", label: "DDS-4", type: "value" },
    { key: "none", label: "None", type: "value" },
    { key: "dat72", label: "DAT-72", type: "value" },
    { key: "dat160", label: "DAT-160", type: "value" },
    { key: "dat320", label: "DAT-320", type: "value" },

    { key: "dlt", label: "DLT", type: "subheader" },
    { key: "dlts4", label: "DLT-S4", type: "value" },
    { key: "dlt1", label: "DLT1", type: "value" },
    { key: "dltvs80", label: "DLT-VS80", type: "value" },
    { key: "dlt2000", label: "DLT2000", type: "value" },
    { key: "dlt4000", label: "DLT4000", type: "value" },
    { key: "dlt7000", label: "DLT7000", type: "value" },
    { key: "dlt8000", label: "DLT8000", type: "value" },

    { key: "sdlt", label: "SDLT", type: "subheader" },
    { key: "sdlt220", label: "SDLT 220", type: "value" },
    { key: "sdlt320", label: "SDLT 320", type: "value" },
    { key: "sdlt600", label: "SDLT 600", type: "value" },

    { key: "3480", label: "3480", type: "subheader" },
    { key: "3480", label: "3480", type: "value" },

    { key: "3490", label: "3490", type: "subheader" },
    { key: "3490", label: "3490", type: "value" },
    { key: "3490e", label: "3490E", type: "value" },

    { key: "sd3", label: "SD-3", type: "subheader" },
    { key: "sd3", label: "SD-3", type: "value" },

    { key: "9840", label: "9840", type: "subheader" },
    { key: "t9840a", label: "T9840A", type: "value" },
    { key: "t9840b", label: "T9840B", type: "value" },
    { key: "t9840c", label: "T9840C", type: "value" },
    { key: "t9840d", label: "T9840D", type: "value" },

    { key: "9940", label: "9940", type: "subheader" },
    { key: "t9940a", label: "T9940A", type: "value" },
    { key: "t9940b", label: "T9940B", type: "value" },

    { key: "3590", label: "3590", type: "subheader" },
    { key: "t3590b", label: "T3590B", type: "value" },
    { key: "t3590e", label: "T3590E", type: "value" },
    { key: "t3590h", label: "T3590H", type: "value" },


    { key: "paper", label: "Paper", type: "value" },
    { key: "vhs", label: "VHS", type: "value" },
    { key: "betacam", label: "BetaCam", type: "value" },
    { key: "hdcam", label: "HD Cam", type: "value" },


];



export const FEE_CURRENCY = [
    {
        "key": "AUD",
        "label": "Australian Dollar"
    },
    {
        "key": "USD",
        "label": "US Dollar"
    },
    {
        "key": "EUR",
        "label": "Euro"
    },
    {
        "key": "GBP",
        "label": "British Pound"
    },
    {
        "key": "CHF",
        "label": "Swiss Franc"
    },
    {
        "key": "NZD",
        "label": "New Zealand Dollar"
    },
    {
        "key": "JPY",
        "label": "Japanese Yen"
    },
    {
        "key": "INR",
        "label": "Indian Rupee"
    },
    {
        "key": "CNY",
        "label": "Chinese Yuan"
    }
    /*
     {
         "key": "XUA",
         "label": "ADB Unit of Account"
     },
     {
         "key": "AFN",
         "label": "Afghan Afghani"
     },
     {
         "key": "AFA",
         "label": "Afghan Afghani [1927–2002)"
     },
     {
         "key": "ALL",
         "label": "Albanian Lek"
     },
     {
         "key": "ALK",
         "label": "Albanian Lek [1946–1965)"
     },
     {
         "key": "DZD",
         "label": "Algerian Dinar"
     },
     {
         "key": "ADP",
         "label": "Andorran Peseta"
     },
     {
         "key": "AOA",
         "label": "Angolan Kwanza"
     },
     {
         "key": "AOK",
         "label": "Angolan Kwanza [1977–1991)"
     },
     {
         "key": "AON",
         "label": "Angolan New Kwanza [1990–2000)"
     },
     {
         "key": "AOR",
         "label": "Angolan Readjusted Kwanza [1995–1999)"
     },
     {
         "key": "ARA",
         "label": "Argentine Austral"
     },
     {
         "key": "ARS",
         "label": "Argentine Peso"
     },
     {
         "key": "ARM",
         "label": "Argentine Peso [1881–1970)"
     },
     {
         "key": "ARP",
         "label": "Argentine Peso [1983–1985)"
     },
     {
         "key": "ARL",
         "label": "Argentine Peso Ley [1970–1983)"
     },
     {
         "key": "AMD",
         "label": "Armenian Dram"
     },
     {
         "key": "AWG",
         "label": "Aruban Florin"
     },
     {
         "key": "ATS",
         "label": "Austrian Schilling"
     },
     {
         "key": "AZN",
         "label": "Azerbaijani Manat"
     },
     {
         "key": "AZM",
         "label": "Azerbaijani Manat [1993–2006)"
     },
     {
         "key": "BSD",
         "label": "Bahamian Dollar"
     },
     {
         "key": "BHD",
         "label": "Bahraini Dinar"
     },
     {
         "key": "BDT",
         "label": "Bangladeshi Taka"
     },
     {
         "key": "BBD",
         "label": "Barbadian Dollar"
     },
     {
         "key": "BYN",
         "label": "Belarusian Ruble"
     },
     {
         "key": "BYB",
         "label": "Belarusian Ruble [1994–1999)"
     },
     {
         "key": "BYR",
         "label": "Belarusian Ruble [2000–2016)"
     },
     {
         "key": "BEF",
         "label": "Belgian Franc"
     },
     {
         "key": "BEC",
         "label": "Belgian Franc [convertible)"
     },
     {
         "key": "BEL",
         "label": "Belgian Franc [financial)"
     },
     {
         "key": "BZD",
         "label": "Belize Dollar"
     },
     {
         "key": "BMD",
         "label": "Bermudan Dollar"
     },
     {
         "key": "BTN",
         "label": "Bhutanese Ngultrum"
     },
     {
         "key": "BOB",
         "label": "Bolivian Boliviano"
     },
     {
         "key": "BOL",
         "label": "Bolivian Boliviano [1863–1963)"
     },
     {
         "key": "BOV",
         "label": "Bolivian Mvdol"
     },
     {
         "key": "BOP",
         "label": "Bolivian Peso"
     },
     {
         "key": "VED",
         "label": "Bolívar Soberano"
     },
     {
         "key": "BAM",
         "label": "Bosnia-Herzegovina Convertible Mark"
     },
     {
         "key": "BAD",
         "label": "Bosnia-Herzegovina Dinar [1992–1994)"
     },
     {
         "key": "BAN",
         "label": "Bosnia-Herzegovina New Dinar [1994–1997)"
     },
     {
         "key": "BWP",
         "label": "Botswanan Pula"
     },
     {
         "key": "BRC",
         "label": "Brazilian Cruzado [1986–1989)"
     },
     {
         "key": "BRZ",
         "label": "Brazilian Cruzeiro [1942–1967)"
     },
     {
         "key": "BRE",
         "label": "Brazilian Cruzeiro [1990–1993)"
     },
     {
         "key": "BRR",
         "label": "Brazilian Cruzeiro [1993–1994)"
     },
     {
         "key": "BRN",
         "label": "Brazilian New Cruzado [1989–1990)"
     },
     {
         "key": "BRB",
         "label": "Brazilian New Cruzeiro [1967–1986)"
     },
     {
         "key": "BRL",
         "label": "Brazilian Real"
     },
     {
         "key": "BND",
         "label": "Brunei Dollar"
     },
     {
         "key": "BGL",
         "label": "Bulgarian Hard Lev"
     },
     {
         "key": "BGN",
         "label": "Bulgarian Lev"
     },
     {
         "key": "BGO",
         "label": "Bulgarian Lev [1879–1952)"
     },
     {
         "key": "BGM",
         "label": "Bulgarian Socialist Lev"
     },
     {
         "key": "BUK",
         "label": "Burmese Kyat"
     },
     {
         "key": "BIF",
         "label": "Burundian Franc"
     },
     {
         "key": "XPF",
         "label": "CFP Franc"
     },
     {
         "key": "HRD",
         "label": "Croatian Dinar"
     },
     {
         "key": "HRK",
         "label": "Croatian Kuna"
     },
     {
         "key": "CUC",
         "label": "Cuban Convertible Peso"
     },
     {
         "key": "CUP",
         "label": "Cuban Peso"
     },
     {
         "key": "CYP",
         "label": "Cypriot Pound"
     },
     {
         "key": "CZK",
         "label": "Czech Koruna"
     },
     {
         "key": "CSK",
         "label": "Czechoslovak Hard Koruna"
     },
     {
         "key": "CNX",
         "label": "Chinese People’s Bank Dollar"
     },
     {
         "key": "CNH",
         "label": "Chinese Yuan [offshore)"
     },
     {
         "key": "COP",
         "label": "Colombian Peso"
     },
     {
         "key": "COU",
         "label": "Colombian Real Value Unit"
     },
     {
         "key": "KMF",
         "label": "Comorian Franc"
     },
     {
         "key": "CDF",
         "label": "Congolese Franc"
     },
     {
         "key": "CRC",
         "label": "Costa Rican Colón"
     },
     {
         "key": "DKK",
         "label": "Danish Krone"
     },
     {
         "key": "DJF",
         "label": "Djiboutian Franc"
     },
     {
         "key": "DOP",
         "label": "Dominican Peso"
     },
     {
         "key": "NLG",
         "label": "Dutch Guilder"
     },
     {
         "key": "XCD",
         "label": "East Caribbean Dollar"
     },
     {
         "key": "DDM",
         "label": "East German Mark"
     },
     {
         "key": "ECS",
         "label": "Ecuadorian Sucre"
     },
     {
         "key": "ECV",
         "label": "Ecuadorian Unit of Constant Value"
     },
     {
         "key": "EGP",
         "label": "Egyptian Pound"
     },
     {
         "key": "GQE",
         "label": "Equatorial Guinean Ekwele"
     },
     {
         "key": "ERN",
         "label": "Eritrean Nakfa"
     },
     {
         "key": "EEK",
         "label": "Estonian Kroon"
     },
     {
         "key": "ETB",
         "label": "Ethiopian Birr"
     },
     {
         "key": "XBA",
         "label": "European Composite Unit"
     },
     {
         "key": "GHS",
         "label": "Ghanaian Cedi"
     },
     {
         "key": "GHC",
         "label": "Ghanaian Cedi [1979–2007)"
     },
     {
         "key": "GIP",
         "label": "Gibraltar Pound"
     },
     {
         "key": "XAU",
         "label": "Gold"
     },
     {
         "key": "GRD",
         "label": "Greek Drachma"
     },
     {
         "key": "GTQ",
         "label": "Guatemalan Quetzal"
     },
     {
         "key": "GWP",
         "label": "Guinea-Bissau Peso"
     },
     {
         "key": "GNF",
         "label": "Guinean Franc"
     },
     {
         "key": "FIM",
         "label": "Finnish Markka"
     },
     {
         "key": "FRF",
         "label": "French Franc"
     },
     {
         "key": "XFO",
         "label": "French Gold Franc"
     },
     {
         "key": "XFU",
         "label": "French UIC-Franc"
     },
     {
         "key": "GMD",
         "label": "Gambian Dalasi"
     },
     {
         "key": "GEK",
         "label": "Georgian Kupon Larit"
     },
     {
         "key": "GEL",
         "label": "Georgian Lari"
     },
     {
         "key": "DEM",
         "label": "German Mark"
     },
     {
         "key": "XEU",
         "label": "European Currency Unit"
     },
     {
         "key": "XBB",
         "label": "European Monetary Unit"
     },
     {
         "key": "XBC",
         "label": "European Unit of Account [XBC)"
     },
     {
         "key": "XBD",
         "label": "European Unit of Account [XBD)"
     },
     {
         "key": "FKP",
         "label": "Falkland Islands Pound"
     },
     {
         "key": "FJD",
         "label": "Fijian Dollar"
     },
     {
         "key": "IDR",
         "label": "Indonesian Rupiah"
     },
     {
         "key": "IRR",
         "label": "Iranian Rial"
     },
     {
         "key": "IQD",
         "label": "Iraqi Dinar"
     },
     {
         "key": "IEP",
         "label": "Irish Pound"
     },
     {
         "key": "ILS",
         "label": "Israeli New Shekel"
     },
     {
         "key": "ILP",
         "label": "Israeli Pound"
     },
     {
         "key": "ILR",
         "label": "Israeli Shekel [1980–1985)"
     },
     {
         "key": "ITL",
         "label": "Italian Lira"
     },
     {
         "key": "GNS",
         "label": "Guinean Syli"
     },
     {
         "key": "GYD",
         "label": "Guyanaese Dollar"
     },
     {
         "key": "HTG",
         "label": "Haitian Gourde"
     },
     {
         "key": "HNL",
         "label": "Honduran Lempira"
     },
     {
         "key": "HKD",
         "label": "Hong Kong Dollar"
     },
     {
         "key": "HUF",
         "label": "Hungarian Forint"
     },
     {
         "key": "IMP",
         "label": "IMP"
     },
     {
         "key": "ISK",
         "label": "Icelandic Króna"
     },
     {
         "key": "ISJ",
         "label": "Icelandic Króna [1918–1981)"
     },
     {
         "key": "LUC",
         "label": "Luxembourgian Convertible Franc"
     },
     {
         "key": "LUF",
         "label": "Luxembourgian Franc"
     },
     {
         "key": "MOP",
         "label": "Macanese Pataca"
     },
     {
         "key": "MKD",
         "label": "Macedonian Denar"
     },
     {
         "key": "MKN",
         "label": "Macedonian Denar [1992–1993)"
     },
     {
         "key": "MGA",
         "label": "Malagasy Ariary"
     },
     {
         "key": "MGF",
         "label": "Malagasy Franc"
     },
     {
         "key": "LVR",
         "label": "Latvian Ruble"
     },
     {
         "key": "LBP",
         "label": "Lebanese Pound"
     },
     {
         "key": "LSL",
         "label": "Lesotho Loti"
     },
     {
         "key": "LRD",
         "label": "Liberian Dollar"
     },
     {
         "key": "LYD",
         "label": "Libyan Dinar"
     },
     {
         "key": "LTL",
         "label": "Lithuanian Litas"
     },
     {
         "key": "LTT",
         "label": "Lithuanian Talonas"
     },
     {
         "key": "LUL",
         "label": "Luxembourg Financial Franc"
     },
     {
         "key": "JMD",
         "label": "Jamaican Dollar"
     },
     {
         "key": "JOD",
         "label": "Jordanian Dinar"
     },
     {
         "key": "KZT",
         "label": "Kazakhstani Tenge"
     },
     {
         "key": "KES",
         "label": "Kenyan Shilling"
     },
     {
         "key": "KWD",
         "label": "Kuwaiti Dinar"
     },
     {
         "key": "KGS",
         "label": "Kyrgystani Som"
     },
     {
         "key": "LAK",
         "label": "Laotian Kip"
     },
     {
         "key": "LVL",
         "label": "Latvian Lats"
     },
     {
         "key": "MCF",
         "label": "Monegasque Franc"
     },
     {
         "key": "MNT",
         "label": "Mongolian Tugrik"
     },
     {
         "key": "MAD",
         "label": "Moroccan Dirham"
     },
     {
         "key": "MAF",
         "label": "Moroccan Franc"
     },
     {
         "key": "MZE",
         "label": "Mozambican Escudo"
     },
     {
         "key": "MZN",
         "label": "Mozambican Metical"
     },
     {
         "key": "MZM",
         "label": "Mozambican Metical [1980–2006)"
     },
     {
         "key": "MMK",
         "label": "Myanmar Kyat"
     },
     {
         "key": "MRO",
         "label": "Mauritanian Ouguiya [1973–2017)"
     },
     {
         "key": "MUR",
         "label": "Mauritian Rupee"
     },
     {
         "key": "MXV",
         "label": "Mexican Investment Unit"
     },
     {
         "key": "MXN",
         "label": "Mexican Peso"
     },
     {
         "key": "MXP",
         "label": "Mexican Silver Peso [1861–1992)"
     },
     {
         "key": "MDC",
         "label": "Moldovan Cupon"
     },
     {
         "key": "MDL",
         "label": "Moldovan Leu"
     },
     {
         "key": "MWK",
         "label": "Malawian Kwacha"
     },
     {
         "key": "MYR",
         "label": "Malaysian Ringgit"
     },
     {
         "key": "MVR",
         "label": "Maldivian Rufiyaa"
     },
     {
         "key": "MVP",
         "label": "Maldivian Rupee [1947–1981)"
     },
     {
         "key": "MLF",
         "label": "Malian Franc"
     },
     {
         "key": "MTL",
         "label": "Maltese Lira"
     },
     {
         "key": "MTP",
         "label": "Maltese Pound"
     },
     {
         "key": "MRU",
         "label": "Mauritanian Ouguiya"
     },
     {
         "key": "PYG",
         "label": "Paraguayan Guarani"
     },
     {
         "key": "PEI",
         "label": "Peruvian Inti"
     },
     {
         "key": "PEN",
         "label": "Peruvian Sol"
     },
     {
         "key": "PES",
         "label": "Peruvian Sol [1863–1965)"
     },
     {
         "key": "PHP",
         "label": "Philippine Peso"
     },
     {
         "key": "XPT",
         "label": "Platinum"
     },
     {
         "key": "PLN",
         "label": "Polish Zloty"
     },
     {
         "key": "NGN",
         "label": "Nigerian Naira"
     },
     {
         "key": "KPW",
         "label": "North Korean Won"
     },
     {
         "key": "NOK",
         "label": "Norwegian Krone"
     },
     {
         "key": "OMR",
         "label": "Omani Rial"
     },
     {
         "key": "PKR",
         "label": "Pakistani Rupee"
     },
     {
         "key": "XPD",
         "label": "Palladium"
     },
     {
         "key": "PAB",
         "label": "Panamanian Balboa"
     },
     {
         "key": "PGK",
         "label": "Papua New Guinean Kina"
     },
     {
         "key": "NAD",
         "label": "Namibian Dollar"
     },
     {
         "key": "NPR",
         "label": "Nepalese Rupee"
     },
     {
         "key": "ANG",
         "label": "Netherlands Antillean Guilder"
     },
     {
         "key": "TWD",
         "label": "New Taiwan Dollar"
     },
     {
         "key": "NIO",
         "label": "Nicaraguan Córdoba"
     },
     {
         "key": "NIC",
         "label": "Nicaraguan Córdoba [1988–1991)"
     },
     {
         "key": "SLE",
         "label": "Sierra Leonean Leone"
     },
     {
         "key": "SLL",
         "label": "Sierra Leonean Leone [1964—2022)"
     },
     {
         "key": "XAG",
         "label": "Silver"
     },
     {
         "key": "SGD",
         "label": "Singapore Dollar"
     },
     {
         "key": "SKK",
         "label": "Slovak Koruna"
     },
     {
         "key": "SIT",
         "label": "Slovenian Tolar"
     },
     {
         "key": "SBD",
         "label": "Solomon Islands Dollar"
     },
     {
         "key": "SOS",
         "label": "Somali Shilling"
     },
     {
         "key": "RUR",
         "label": "Russian Ruble [1991–1998)"
     },
     {
         "key": "RWF",
         "label": "Rwandan Franc"
     },
     {
         "key": "SVC",
         "label": "Salvadoran Colón"
     },
     {
         "key": "WST",
         "label": "Samoan Tala"
     },
     {
         "key": "SAR",
         "label": "Saudi Riyal"
     },
     {
         "key": "RSD",
         "label": "Serbian Dinar"
     },
     {
         "key": "CSD",
         "label": "Serbian Dinar [2002–2006)"
     },
     {
         "key": "SCR",
         "label": "Seychellois Rupee"
     },
     {
         "key": "PLZ",
         "label": "Polish Zloty [1950–1995)"
     },
     {
         "key": "PTE",
         "label": "Portuguese Escudo"
     },
     {
         "key": "GWE",
         "label": "Portuguese Guinea Escudo"
     },
     {
         "key": "QAR",
         "label": "Qatari Riyal"
     },
     {
         "key": "XRE",
         "label": "RINET Funds"
     },
     {
         "key": "RHD",
         "label": "Rhodesian Dollar"
     },
     {
         "key": "RON",
         "label": "Romanian Leu"
     },
     {
         "key": "ROL",
         "label": "Romanian Leu [1952–2006)"
     },
     {
         "key": "SRD",
         "label": "Surinamese Dollar"
     },
     {
         "key": "SRG",
         "label": "Surinamese Guilder"
     },
     {
         "key": "SZL",
         "label": "Swazi Lilangeni"
     },
     {
         "key": "SEK",
         "label": "Swedish Krona"
     },
     {
         "key": "CHF",
         "label": "Swiss Franc"
     },
     {
         "key": "ESB",
         "label": "Spanish Peseta [convertible account)"
     },
     {
         "key": "XDR",
         "label": "Special Drawing Rights"
     },
     {
         "key": "LKR",
         "label": "Sri Lankan Rupee"
     },
     {
         "key": "SHP",
         "label": "St. Helena Pound"
     },
     {
         "key": "XSU",
         "label": "Sucre"
     },
     {
         "key": "SDD",
         "label": "Sudanese Dinar [1992–2007)"
     },
     {
         "key": "SDG",
         "label": "Sudanese Pound"
     },
     {
         "key": "KRH",
         "label": "South Korean Hwan [1953–1962)"
     },
     {
         "key": "KRW",
         "label": "South Korean Won"
     },
     {
         "key": "KRO",
         "label": "South Korean Won [1945–1953)"
     },
     {
         "key": "SSP",
         "label": "South Sudanese Pound"
     },
     {
         "key": "SUR",
         "label": "Soviet Rouble"
     },
     {
         "key": "ESP",
         "label": "Spanish Peseta"
     },
     {
         "key": "ESA",
         "label": "Spanish Peseta [A account)"
     },
     {
         "key": "ZWL",
         "label": "Zimbabwean Dollar [2009)"
     },
     {
         "key": "ZRN",
         "label": "Zairean New Zaire [1993–1998)"
     },
     {
         "key": "ZRZ",
         "label": "Zairean Zaire [1971–1993)"
     },
     {
         "key": "ZMW",
         "label": "Zambian Kwacha"
     },
     {
         "key": "ZMK",
         "label": "Zambian Kwacha [1968–2012)"
     },
     {
         "key": "ZWD",
         "label": "Zimbabwean Dollar [1980–2008)"
     },
     {
         "key": "ZWR",
         "label": "Zimbabwean Dollar [2008)"
     },
     {
         "key": "YUN",
         "label": "Yugoslavian Convertible Dinar [1990–1992)"
     },
     {
         "key": "YUD",
         "label": "Yugoslavian Hard Dinar [1966–1990)"
     },
     {
         "key": "YUM",
         "label": "Yugoslavian New Dinar [1994–2002)"
     },
     {
         "key": "YUR",
         "label": "Yugoslavian Reformed Dinar [1992–1993)"
     },
     {
         "key": "ZWN",
         "label": "ZWN"
     },
     {
         "key": "VNN",
         "label": "Vietnamese Dong [1978–1985)"
     },
     {
         "key": "CHE",
         "label": "WIR Euro"
     },
     {
         "key": "CHW",
         "label": "WIR Franc"
     },
     {
         "key": "XOF",
         "label": "West African CFA Franc"
     },
     {
         "key": "YDD",
         "label": "Yemeni Dinar"
     },
     {
         "key": "YER",
         "label": "Yemeni Rial"
     },
     {
         "key": "UZS",
         "label": "Uzbekistani Som"
     },
     {
         "key": "VUV",
         "label": "Vanuatu Vatu"
     },
     {
         "key": "VES",
         "label": "Venezuelan Bolívar"
     },
     {
         "key": "VEB",
         "label": "Venezuelan Bolívar [1871–2008)"
     },
     {
         "key": "VEF",
         "label": "Venezuelan Bolívar [2008–2018)"
     },
     {
         "key": "VND",
         "label": "Vietnamese Dong"
     },
     {
         "key": "UAK",
         "label": "Ukrainian Karbovanets"
     },
     {
         "key": "AED",
         "label": "United Arab Emirates Dirham"
     },
     {
         "key": "UYW",
         "label": "Uruguayan Nominal Wage Index Unit"
     },
     {
         "key": "UYU",
         "label": "Uruguayan Peso"
     },
     {
         "key": "UYP",
         "label": "Uruguayan Peso [1975–1993)"
     },
     {
         "key": "UYI",
         "label": "Uruguayan Peso [Indexed Units)"
     },
     {
         "key": "TMM",
         "label": "Turkmenistani Manat [1993–2009)"
     },
     {
         "key": "USN",
         "label": "US Dollar [Next day)"
     },
     {
         "key": "USS",
         "label": "US Dollar [Same day)"
     },
     {
         "key": "UGX",
         "label": "Ugandan Shilling"
     },
     {
         "key": "UGS",
         "label": "Ugandan Shilling [1966–1987)"
     },
     {
         "key": "UAH",
         "label": "Ukrainian Hryvnia"
     },
     {
         "key": "THB",
         "label": "Thai Baht"
     },
     {
         "key": "TPE",
         "label": "Timorese Escudo"
     },
     {
         "key": "TOP",
         "label": "Tongan Paʻanga"
     },
     {
         "key": "TTD",
         "label": "Trinidad & Tobago Dollar"
     },
     {
         "key": "TND",
         "label": "Tunisian Dinar"
     },
     {
         "key": "TRY",
         "label": "Turkish Lira"
     },
     {
         "key": "TRL",
         "label": "Turkish Lira [1922–2005)"
     },
     {
         "key": "TMT",
         "label": "Turkmenistani Manat"
     },
     {
         "key": "STN",
         "label": "São Tomé & Príncipe Dobra"
     },
     {
         "key": "STD",
         "label": "São Tomé & Príncipe Dobra [1977–2017)"
     },
     {
         "key": "TVD",
         "label": "TVD"
     },
     {
         "key": "TJR",
         "label": "Tajikistani Ruble"
     },
     {
         "key": "TJS",
         "label": "Tajikistani Somoni"
     },
     {
         "key": "TZS",
         "label": "Tanzanian Shilling"
     },
     {
         "key": "XTS",
         "label": "Testing Currency Code"
     },
     {
         "key": "SDG",
         "label": "Sudanese Pound"
     },
     {
         "key": "SDP",
         "label": "Sudanese Pound [1957–1998)"
     },
     {
         "key": "SRD",
         "label": "Surinamese Dollar"
     },
     {
         "key": "SRG",
         "label": "Surinamese Guilder"
     },
     {
         "key": "SZL",
         "label": "Swazi Lilangeni"
     },
     {
         "key": "SEK",
         "label": "Swedish Krona"
     },
     {
         "key": "SYP",
         "label": "Syrian Pound"
     }
     */
]


export const TAPES_ARRAY=[
    {fileName:'8mm',filePath:'/assets/img/tapearkimage/8mm.128w.png'},
    {fileName:'LTO3',filePath:'/assets/img/tapearkimage/LTO3.128w.png'},
    {fileName:'LTO4',filePath:'/assets/img/tapearkimage/LTO4.128w.png'},
    {fileName:'LTO5',filePath:'/assets/img/tapearkimage/LTO5.128w.png'},
    {fileName:'LTO6',filePath:'/assets/img/tapearkimage/LTO6.128w.png'},
    {fileName:'LTO7',filePath:'/assets/img/tapearkimage/LTO7.128w.png'},
    {fileName:'LTO8',filePath:'/assets/img/tapearkimage/LTO8.128w.png'},
    {fileName:'',filePath:'/assets/img/tapearkimage/unknownImage.png'},
]
