import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import axios from "axios";
import { getApiUrl } from "./../../global";
import { Button, Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import moment from "moment";

function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24),
    createData('Ice cream sandwich', 237, 9.0, 37),
    createData('Eclair', 262, 16.0, 24),
    createData('Cupcake', 305, 3.7, 67),
    createData('Gingerbread', 356, 16.0, 49),
];

export const Sessions = () => {
    const classes = useStyles();
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = React.useState(0);

    const [sessionsList, setSessionsList] = React.useState({});
    const handlePageChange = (event, page) => {
        // hangle page change
        setCurrentPage(page);
        console.log(page);
        //setCurrentPage(page);
    };
    useEffect(() => {
        getSessionsData();
    }, [currentPage])
    const getSessionsData = async () => {
        let url = await getApiUrl();
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + `/auditlogs/?page=${currentPage}`;
        axios
            .post(apiUrl, { search: "" })
            .then(data => {
                data = data.data;
                // let orderDoc = JSON.parse(JSON.stringify(data.data.results));
                // orderDoc = orderDoc.filter((file) => file.file_type == fileType.USERTAPELIST);
                console.log(groupByDate(data.results));
                setSessionsList(groupByDate(data.results));
                setCount(data.count);
                setTotalPages(Math.ceil(data.count / 10));
            })
            .catch(error => {
                console.log(error);
            });

    };
    const groupByDate = (response) => response.reduce((acc, obj) => {
        const date = new Date(obj.timestamp).toLocaleDateString();
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(obj)
        return acc
    }, {})
    const getActionText = (row) => {
        if (row?.action == "updated") {
            for (const key in row?.changes) {
                const element = row?.changes[key];
                if (key == 'last_login') {
                    return `${key} : ${moment(element[0]).format('YYYY-MM-DD hh:mm:ss A')}`
                } else {
                    return `${key} : ${element[0]} > ${element[1]}`
                }
            }
        } else {
            return `${row.actor}  ${row.entity} ${row.resource} ${row.action}`
        }
        // row?.changes?.last_login && row?.changes?.last_login[0] ? (moment(row?.changes?.last_login[0]).format('YYYY-MM-DD hh:mm:ss A')) :
        //     (row?.changes?.status[0] + '    >    ' + row?.changes?.status[1]) :
        // row?.changes?.title[1]
    }
    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, count);
    return (
        <>
            {
                Object.keys(sessionsList).map(date => {
                    console.log("date", date);
                    return <Grid container direction='column' spacing={1} key={date} style={{ margin: '10px' }}>
                        <Grid item>
                            <Typography align='left'>{date}</Typography>
                        </Grid>
                        {/* <Grid item>
                            <Typography align='left'>Today</Typography>
                            </Grid> */}
                        <Grid item>
                            <div style={{ border: '1px solid #5F5F5F' }}>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {sessionsList[date].map((row) => (
                                                <TableRow
                                                    key={row.timestamp}>
                                                    <TableCell align='center' className={classes.tableRightborder} style={{ borderBottom: 'none', color: 'white', fontWeight: 400, fontSize: '18px', lineHeight: '22px', width: '200px' }}>
                                                        {moment(row.timestamp).format('hh:mm:ss A')}
                                                    </TableCell>
                                                    <TableCell align='center' className={classes.tableRightborder} style={{ borderBottom: 'none', color: 'white', fontWeight: 400, fontSize: '18px', lineHeight: '22px', width: '120px' }}>{row.role}</TableCell>
                                                    <TableCell align='center' className={classes.tableRightborder} style={{ borderBottom: 'none', color: 'white', fontWeight: 400, fontSize: '18px', lineHeight: '22px' }}>{`${row.entity} ${row.resource} ${row.action}`}</TableCell>
                                                    <TableCell align='center' style={{ borderBottom: 'none', color: 'white', fontWeight: 400, fontSize: '18px', lineHeight: '22px' }}>
                                                        {
                                                            getActionText(row)
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </Grid>
                    </Grid>
                })
            }
            {/* <Grid item xs={12}>
                    <Grid container
                        direction="row"
                        justifyContent="space-between"
                        padding={2}
                        style={{ paddingLeft: '0px', paddingRight: '0px', minWidth: '55%' }}>
                        <Grid item ml={2}>
                            <Typography sx={{ fontFamily: 'Poppins', fontSize: '16px', lineHeight: '24px', fontWeight: 400, color: '#FFFFFF' }}>
                                {`Showing ${startIndex}-${endIndex} of ${count} Orders`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Pagination
                                component="div"
                                rowsPerPage={10}
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                sx={{
                                    '& .MuiPaginationItem-root': {
                                        width: '30px',
                                        height: '30px',
                                        border: '1px solid #FFFFFF',
                                        background: '#3A3E3F',
                                        color: '#FFFFFF',
                                        margin: { md: '0 4px', xs: '0 2px' },
                                        '&.Mui-selected': {
                                            background: '#FFC540',
                                            color: '#3A3E3F',
                                        },
                                    },
                                    '& .MuiPaginationItem-page.Mui-selected:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-page:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                    '& .MuiPaginationItem-previous, & .MuiPaginationItem-next': {
                                        width: '100px',
                                        height: '40px',
                                        borderRadius: '8px',
                                        border: '1px solid #FFFFFF',
                                        color: '#FFFFFF',
                                        background: '#3A3E3F',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    '& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover': {
                                        background: '#FFC540',
                                        color: '#3A3E3F',
                                    },
                                }}

                                renderItem={(item) => (
                                    <PaginationItem {...item} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
        </>
    );
}

const useStyles = makeStyles(theme => ({
    tableRightborder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: '#5F5F5F',
        borderStyle: 'solid',
        borderBottom: 'none'
        //color:'#FFFFFF'

    }

}));