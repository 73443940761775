import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from "moment";
import { getLoginUser } from '../../LocalStorageService';


export const Details = ({ orderDetail, customer }) => {
    const classes = useStyles();
    const [logInUser, setLogInUser] = React.useState();
    React.useEffect(() => {
        setLogInUser(getLoginUser());
    }, [])
    return (
        <Box p={2}>
            <Grid container direction='column' spacing={1} xs={8}>
                <Grid item>
                    <Typography align='left' className={classes.mainHeading}>Order Details</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Order ID </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{orderDetail.order_number}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Client Name</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{orderDetail.customer_name}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>TapeArk Client ID</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{customer?.arkbridge_customer_id ? customer?.arkbridge_customer_id : logInUser?.user?.arkbridge_customer_id}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item xs={4}>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>TapeArk Job ID</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{orderDetail.tapeark_job_id}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Quote Reference ID</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{orderDetail.quote_reference_id}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Contact Person</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{customer?.email}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Typography align='left' className={classes.subHeading}>Project Scope</Typography>
                </Grid>
                <Grid item>
                    <Typography align='left' className={classes.data}>{orderDetail.project_scope}</Typography>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Typography align='left' className={classes.subHeading}>Project Deliverables</Typography>
                </Grid>
                <Grid item>
                    <Typography align='left' className={classes.data}>{orderDetail.project_deliverables}</Typography>
                    <hr className={classes.horizontalLine} />
                </Grid>

                <Grid item>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Security and Privacy<br /> Evaluation and Assesment</Typography>
                        </Grid>
                        <Grid item>
                            <Typography align='left' className={classes.data}>{orderDetail.job_security_privacy}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>SOW Commence</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{moment(orderDetail?.sow_start).format("MMM/DD/YYYY")}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.subHeading}>SOW Completion</Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.data}>{moment(orderDetail?.sow_end).format("MMM/DD/YYYY")}</Typography>
                        </Grid>
                    </Grid>
                    <hr className={classes.horizontalLine} />
                </Grid>
                <Grid item>
                    <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                        <Grid item style={{ minWidth: '200px' }}>
                            <Typography align='left' className={classes.subHeading}>Billing</Typography>
                        </Grid>
                        <Grid item>
                            <Typography align='left' className={classes.data}>{orderDetail.billing}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
const useStyles = makeStyles({
    mainHeading: {
        fontWeight: 500,
        fontSize: '26px',
        color: '#FFFFFF',
        lineHeight: '26px'
    },
    subHeading: {
        fontWeight: 500,
        fontSize: '16px',
        color: '#BABABA',
        lineHeight: '19.36px'
    },
    data: {
        fontWeight: 500,
        fontSize: '19px',
        lineHeight: '28px',
        color: '#FFFFFF',
        lineBreak: "anywhere"
    },
    horizontalLine: {
        height: '1px',
        color: '#6E6D6D',
        backgroundColor: '#6E6D6D',
        borderWidth: '0px'
    }
});