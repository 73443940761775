import React, { useState } from "react";
import Slider from "@mui/material/Slider";
import { PrimaryColor } from "../theme/theme";
import {
  Stack,
  Toolbar,
  Tooltip,
  Grid,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { getLoginUser } from "../../LocalStorageService";
import { getApiUrl } from "../../global";
import { formats } from "../../global";
import axios from "axios";
import CommonTable from "../../views/Admin/Order/OrderManagement/Audit/CommonTable";

const CustomSlider = styled(Slider)({
  color: "#D9D9D9",
  height: 15,
  "& .MuiSlider-track": {
    height: 15,
    border: "none",
    color: "#FFC540",
  },
  "& .MuiSlider-thumb": {
    height: 32,
    width: 32,
    backgroundColor: "#FFC540",
    border: "none",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    fontFamily: "Poppins",
    fontWeight: 400,
    lineHeight: 1.2,
    fontSize: 16,
    background: "unset",
    padding: 0,
    width: 48,
    height: 48,
    color: "#3A3E3F",
    borderRadius: "50% 50% 50% 50%",
    backgroundColor: "#FFC540",
    transformOrigin: "bottom left",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translateY(0%)",
    },
    "& > *": {},
  },
});

export const Ingestion = ({ value, orderDetail, isAdmin }) => {



  const useStyles = makeStyles({
    rootMenuItem: {
      "&$selected": {
        backgroundColor: "red",
        "&:hover": {
          backgroundColor: "green",
          borderRadius: 12,
          border: "1px solid var(--White, #FFF)",
        },
      },
      "&:hover": {
        backgroundColor: "transparent",
        border: 0,
        borderRadius: 12,
        border: "1px solid var(--White, #FFF)",
      },
    },
    highlight: {
      background: "#FFC540",
      color: "#3A3E3F",
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
    selectLabel: {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          borderColor: "#C7CCD0",
        },
        "&:hover fieldset": {
          borderColor: "#C7CCD0",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#C7CCD0",
        },
      },
      "& .MuiOutlinedInput-input": {
        color: "#FFFFFF",
      },
      "& .MuiInputLabel-root": {
        color: "rgba(255, 255, 255, 0.5)",
        "&.Mui-focused": {
          color: "#FFC540",
        },
      },
      "& svg": {
        color: "#FFFFFF",
      },
    },
    checkedColr: {
      "&.Mui-checked": {
        color: "red",
      },
    },
  });
  const classes = useStyles();
  let state = [
    {
      key: "audit_complete",
      value: "Ingestion Awaited",
    },
    {
      key: "ingestion_started",
      value: "Ingestion Started",
    },
    {
      key: "ingestion_complete",
      value: "Ingestion Complete",
    },
  ];
  const getCustomerDetails = async () => {
    const customerId = getLoginUser()
      ? getLoginUser().user.customer_uuid
      : null;
    let url = await getApiUrl();
    let apiUrlcustomer =
      url + process.env.REACT_APP_API_BASE_URL + `/customers/${customerId}/`;
    axios
      .get(apiUrlcustomer, {})
      .then((response) => {
        if (response?.status == 200) {
          console.log("Data", JSON.stringify(response.data));

          // setData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
    let _slice = allRestoreList.slice(
      (newPage - 1) * 10,
      (newPage - 1) * 10 + 10
    );
    setRestoreData(_slice);
  };

  function mapOrderStep(_state) {
    if (_state) {
      let step = state.find((e) => e.key.toLowerCase() == _state.toLowerCase());
      return step?.value ? step.value : _state;
    } else {
      return _state;
    }
  }
  const [allRestoreList, setAllRestoreList] = useState([]);
  const [restoreData, setRestoreData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tapeCount, setTapeCount] = useState(0);
  const [completedTap, setCompletedTap] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");

  const startIndex = (currentPage - 1) * 10 + 1;
  const endIndex = Math.min(currentPage * 10, tapeCount);
  React.useEffect(() => {
    // customers/641fb206-efd9-4197-84fe-681cdd85aac5/
    getCustomerDetails();
    let payload = {
      type: "man_item_list",
      request: {
        job_id: orderDetail.tapeark_job_id
      },
    };
    // if (isAdmin) {
    //   let dynamicArray = ''
    //   const dynamicObject = Object.values(fakeResponse.result)
    //   dynamicObject.forEach(i =>
    //     dynamicArray = i.data)
    //   setAllRestoreList(
    //     dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
    //   );
    //   setRestoreData(
    //     dynamicArray
    //       .filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
    //       .slice(0, 10)
    //   );
    //   setTotalPages(
    //     Math.ceil(
    //       dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3))
    //         .length / 10
    //     )
    //   );
    //   setTapeCount(
    //     dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id || 3)).length
    //   );
    //   setCompletedTap(
    //     dynamicArray
    //       .filter((f) => f.job_id == (orderDetail.tapeark_job_id || 3))
    //       .filter(
    //         (i) =>
    //           i.status == "Read Success" ||
    //           i.status == "Upload Success" ||
    //           i.status == "Extract Success"
    //       ).length
    //   );
    // }
    // setRestoreData(fakeResponse.result["31"].data);
    // setTapeCount(fakeResponse.result["31"].data.length);
    // setCompletedTap(
    //   fakeResponse.result["31"].data.filter((i) => i.status == "Cataloged").length
    // );
    axios
      .post(formats, payload)
      .then((response) => {
        if (response?.status == 200) {
          let dynamicArray = ''
          const dynamicObject = Object.values(response.data.result)
          dynamicObject.forEach(i => {
            if (Array.isArray(i.data) && i.data.length) {
              dynamicArray = i.data
            }
          }
          )
          setAllRestoreList(
            dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id))
          );
          setRestoreData(
            dynamicArray
              .filter((i) => i.job_id == (orderDetail.tapeark_job_id))
              .slice(0, 10)
          );
          setTotalPages(
            Math.ceil(
              dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id))
                .length / 10
            )
          );
          setTapeCount(
            dynamicArray.filter((i) => i.job_id == (orderDetail.tapeark_job_id)).length
          );
          setCompletedTap(
            dynamicArray
              .filter((f) => f.job_id == (orderDetail.tapeark_job_id))
              .filter(
                (i) =>
                  i.status_id == 3 ||
                  i.status_id == 4 ||
                  i.status_id == 11
              ).length
          );
        }
      })

      .catch((error) => {
        console.log(error);
      });


  }, []);

  return (
    <Box padding={6} minHeight={"100vh"}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography
          sx={{
            flex: "1 1 100%",
            pt: 2,
            textAlign: "left",
            px: 2,
            fontFamily: "Poppins",
            fontWeight: 700,
            fontSize: "22px",
            color: "#FFFFFF",
          }}
          id="state-title"
          component="div"
        >
          {/* Changes here to show appropriate status message */}
          {/* {(value === 100) ? 'Ingestion Completed' : 'Ingestion Started'} */}
          {mapOrderStep(orderDetail.status)}
        </Typography>
        {/* <Typography
          sx={{
            pt: 2,
            pr: 2,
            fontFamily: "Poppins",
            fontWeight: 400,
            fontSize: "18px",
            lineHeight: "27px",
            color: "#C7CCD0",
          }}
          id="state-completion-time"
          component="div"
          noWrap
          width={"-webkit-fill-available"}
          style={{ textAlign: "right" }}
        >
          6 July, 23
        </Typography> */}
      </Box>

      <Typography
        sx={{
          flex: "1 1 100%",
          pt: 2,
          textAlign: "left",
          px: 2,
          fontFamily: "Poppins",
          fontWeight: 400,
          fontSize: "18px",
          color: "#FFFFFF",
        }}
        id="state-text"
        component="div"
      >
        Your tapes are being ingested. You will be notified once it is
        completed.
      </Typography>

      <Box style={{ position: "relative" }}>
        <CustomSlider
          disabled
          value={isNaN(((completedTap / tapeCount) * 100).toFixed(2)) ? 0 : ((completedTap / tapeCount) * 100).toFixed(2)}
          valueLabelDisplay="on"
          valueLabelFormat={(v) => `${v}%`}
        />
        <Box style={{
          width: "91px",
          height: "95px",
          borderRadius: "8px",
          border: "1px solid #FFC540",
          position: "absolute",
          top: "calc(100% + 8px)",
          //left: `calc(${(value / 100) * 100}% - 45.5px)`,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: PrimaryColor,
          zIndex: 1,
          marginLeft: `calc(${isNaN(((completedTap / tapeCount) * 100).toFixed(2)) ? 0 : ((completedTap / tapeCount) * 100).toFixed(2)}% - 40px)`
        }}>
          <Stack direction={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "33px",
                color: "#FFC540",
              }}
            >
              {completedTap}/{tapeCount}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FFFFFF",
              }}
            >
              Tapes Ingested
            </Typography>
          </Stack>
        </Box>
      </Box>
      <CommonTable
        startIndex={startIndex}
        endIndex={endIndex}
        handlePageChange={handlePageChange}
        restoreData={restoreData}
        totalPages={totalPages}
        currentPage={currentPage}
        tapeCount={tapeCount}
        classes={classes}

      />
    </Box>
  );
};
