import * as React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from "@mui/styles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from "moment";
import { totals, ingested } from "../../global";
import axios from "axios";

// const rows = [
//     { Name: 'E05123', DateCreated: '21-Jan-2023', TimeRemaining: 675, color: "#BD5F5F" },
//     { Name: 'A67432', DateCreated: '21-Jan-2023', TimeRemaining: 68, color: "#F4BF37" },
//     { Name: 'F00042', DateCreated: '21-Jan-2023', TimeRemaining: 6.0, color: "#F4BF37" },
//     { Name: 'B23524', DateCreated: '21-Dec-2023', TimeRemaining: 6.0, color: "#38AA58" },
//     { Name: 'B23524', DateCreated: '21-Dec-2024', TimeRemaining: 6.0, color: "#007E23" },
// ]
export const TapeDeletion = () => {
    const classes = useStyles();
    const [rows, setRows] = React.useState([]);

    const getColorAsPerDiffrence = (date) => {
        let dateOne = moment();
        let dateTwo = moment(date);
        let difference = dateOne.diff(dateTwo, 'days');
        if (difference > 90) {
            return "#007E23";
        } else if (difference <= 90 && difference >= 0) {
            return "#F4BF37";
        } else {
            return "#BD5F5F";
        }
    }
    React.useEffect(() => {
        getData()
    }, []);
    const getData = () => {
        // let payload = {
        //     type: 'rep_tapes_due_for_deletion',
        //     "request": {
        //         "cid": 15,
        //         "type": "totals"
        //     }
        // };
        // axios.post(totals, payload)
        //     .then(response => {
        //         if (response?.status == 200) {
        //             // { Name: 'E05123', DateCreated: '21-Jan-2023', TimeRemaining: 675, color: "#BD5F5F" }
        //         }
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
    }
    return (
        <div className={classes.mainContainer}>
            <Box p={3}>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Tapes Expiry List</Typography>
                            </Grid>
                            {/* <Grid item>
                                <CloseIcon className={classes.closeIcon} />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TableContainer style={{ backgroundColor: 'transparent' }}>
                            <Table sx={{ maxWidth: '100%' }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}>Tape Volser/Barcode ID</TableCell>
                                        <TableCell sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}>Days Remaining until Expiry</TableCell>
                                        <TableCell sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}>Action Required</TableCell>
                                        <TableCell sx={{ marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700" }}>Date Created</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row) => {
                                        return (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 400, fontSize: '20px' }}>{row.Name}</TableCell>
                                                <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 400, fontSize: '20px' }}>{row.DateCreated}</TableCell>
                                                <TableCell align="left" style={{ color: '#FFFFFF', fontWeight: 400, fontSize: '20px' }}>{row.TimeRemaining}</TableCell>
                                                <TableCell align="left"><div style={{ width: '40px', height: '20px', background: `${getColorAsPerDiffrence(row.DateCreated)}` }}></div></TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

const useStyles = makeStyles({
    mainContainer: {
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
        maxWidth: '100%'
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    tableHeading: {
        color: '#FFFFFF',
        fontWeight: 400,
        fontSize: '14px'
    },
    tableData: {
        color: '#FFFFFF',
        fontWeight: 400,
        fontSize: '20px'
    }
});