import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Paper, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";
import { ButtonColor } from "../theme/theme";
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { ToggleButton } from "../home/home";
import { ageing } from "../../global";
import axios from "axios";
import { useFeatures } from "../../views/FeaturesProvider/featuresProvider";
import { DateField } from "@mui/x-date-pickers";
const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
const ageBracket = {
    OVER_8_YEARS: 'age >= 8 years',
    BETWEEN_5_AND_8_YEARS: '5 years >= age > 8 years',
    BETWEEN_1_AND_5_YEARS: '1 years >= age > 5 years',
    BETWEEN_6_MONTH_AND_1_YEARS: '6 months >= age > 1 year',
    BETWEEN_1_MONTH_AND_6_MONTHS: '1 months >= age > 6 months',
    UNDER_1_MONTH: 'age < 1 month',
}
const calculateAgeCategory = (fileDate) => {
    const today = new Date();
    const _fileDate = new Date(fileDate);
    // calculate age in milisec
    const ageInMilSec = today - _fileDate;
    // convert age in years
    const ageInYears = ageInMilSec / (1000 * 60 * 60 * 24 * 365);
    // Determine Category Based On Years
    if (ageInYears >= 8) {
        return ageBracket.OVER_8_YEARS;
    } else if (ageInYears >= 5) {
        return ageBracket.BETWEEN_5_AND_8_YEARS;
    } else if (ageInYears >= 1) {
        return ageBracket.BETWEEN_1_AND_5_YEARS;
    } else if (ageInYears >= 1 / 2) {
        return ageBracket.BETWEEN_6_MONTH_AND_1_YEARS;
    } else if (ageInYears >= 1 / 12) {
        return ageBracket.BETWEEN_1_MONTH_AND_6_MONTHS;
    } else {
        return ageBracket.UNDER_1_MONTH;
    }
}
export const FileUp = ({ dateFilter, dateRange }) => {

    const classes = useStyles();
    const [date, setDate] = React.useState("month");
    const features = useFeatures()
    const [filter, setFilter] = React.useState("");
    const [filesData, setFilesData] = React.useState([]);
    const [totalStorage, setTotalStorage] = React.useState(null);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');

    const [isAdmin, setIsAdmin] = useState(false);
    const [totalFetched, setTotalFetched] = useState(false);
    useEffect(() => {
        if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) {
            setIsAdmin(true);
            if (!totalFetched) {
                getAgingData(true)
                setTotalFetched(true);
            }
        } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {
            setIsAdmin(false);
            if (!totalFetched) {
                getAgingData(false)
                setTotalFetched(true);
            }
        }
    }, [features])

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    const handleChange = (event, newAlignment) => {
        setDate(newAlignment);
    };

    React.useEffect(() => {
        if (dateFilter) {
            getStartAndEndDate();
        }
        if (totalFetched) {
            getAgingData(isAdmin)
        }
    }, [dateFilter, dateRange]);

    const formatDate = date => {
        return date.toISOString().split('T')[0];
    }
    const getStartAndEndDate = () => {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()
        const currentMonth = currentDate.getMonth() + 1
        if (dateFilter == "current_year") {
            setStartDate(formatDate(new Date(currentYear, 0, 1)));
            setEndDate(formatDate(new Date(currentYear, 11, 31)));
            return { startDate: formatDate(new Date(currentYear, 0, 1)), endDate: formatDate(new Date(currentYear, 11, 31)) };
        } else if (dateFilter == "last_month") {
            let lastMonthYear, lastMonthNumber;
            if (currentMonth === 1) {
                lastMonthYear = currentYear - 1;
                lastMonthNumber = 12
            } else {
                lastMonthYear = currentYear
                lastMonthNumber = currentMonth - 1
            }

            setStartDate(formatDate(new Date(lastMonthYear, lastMonthNumber - 1, 1)))
            setEndDate(formatDate(new Date(currentYear, currentMonth - 1, 0)))
            return { startDate: formatDate(new Date(lastMonthYear, lastMonthNumber - 1, 1)), endDate: formatDate(new Date(currentYear, currentMonth - 1, 0)) };

        } else if (dateFilter == "current_month") {
            setStartDate(formatDate(new Date(currentYear, currentMonth - 1, 1)))
            setEndDate(formatDate(new Date(currentYear, currentMonth - 1, 31)))
            return { startDate: formatDate(new Date(currentYear, currentMonth - 1, 1)), endDate: formatDate(new Date(currentYear, currentMonth - 1, 31)) };
        } else if (dateFilter == "custom_date") {
            return { startDate: formatDate(dateRange.startDate), endDate: formatDate(dateRange.endDate) }
        } else {
            console.log("dsdf")
        }
    }
    const getAgingData = (_isAdmin) => {
        let dateData = getStartAndEndDate();
        let payload = {
            type: 'rep_ageing',
            "request": {
                "cid": 15,
                "type": "ageing",
                "date_from": dateData.startDate,
                "date_to": dateData.endDate,

            }
        };
        axios.post(ageing, payload)
            .then(response => {
                // debugger
                let allFilesData = [];
                if (response?.status == 200) {
                    let dynamicArray;
                    let dynamicObject;
                    if (!_isAdmin) {
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i =>
                            dynamicArray = i.data)
                    } else {
                        dynamicArray = [];
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i => { if (i && i.data) { dynamicArray = dynamicArray.concat(i.data) } })

                    }
                    // let dynamicArray = ''
                    // const dynamicObject = Object.values(response.data.result)
                    // dynamicObject.forEach(i =>
                    //     dynamicArray = i.data)
                    const sum = dynamicArray?.reduce((accumulator, currentValue) => accumulator + currentValue.bytes, 0);
                    const totalFiles = dynamicArray?.reduce((accumulator, currentValue) => accumulator + currentValue.files, 0);

                    // const sum = response?.data?.result.reduce((accumulator, currentValue) => accumulator + currentValue.files, 0);
                    setTotalStorage(formatBytes(sum, 2))
                    // response?.data?.result.forEach(element => {
                    dynamicArray.forEach(element => {

                        // console.log(calculateAgeCategory(element.date));
                        if (ageBracket.OVER_8_YEARS == calculateAgeCategory(element.date)) {
                            !allFilesData[0]?.label ? (allFilesData[0] = { label: ageBracket.OVER_8_YEARS, data: 0 + element.files }) :
                                (allFilesData[0] = { label: ageBracket.OVER_8_YEARS, data: allFilesData[0].data + element.files })
                        } else if (ageBracket.BETWEEN_5_AND_8_YEARS == calculateAgeCategory(element.date)) {
                            !allFilesData[1]?.label ? (allFilesData[1] = { label: ageBracket.BETWEEN_5_AND_8_YEARS, data: 0 + element.files }) :
                                (allFilesData[1] = { label: ageBracket.BETWEEN_5_AND_8_YEARS, data: allFilesData[1].data + element.files })
                        } else if (ageBracket.BETWEEN_1_AND_5_YEARS == calculateAgeCategory(element.date)) {
                            !allFilesData[2]?.label ? (allFilesData[2] = { label: ageBracket.BETWEEN_1_AND_5_YEARS, data: 0 + element.files }) :
                                (allFilesData[2] = { label: ageBracket.BETWEEN_1_AND_5_YEARS, data: allFilesData[2].data + element.files })
                        } else if (ageBracket.BETWEEN_6_MONTH_AND_1_YEARS == calculateAgeCategory(element.date)) {
                            !allFilesData[3]?.label ? (allFilesData[3] = { label: ageBracket.BETWEEN_6_MONTH_AND_1_YEARS, data: 0 + element.files }) :
                                (allFilesData[3] = { label: ageBracket.BETWEEN_6_MONTH_AND_1_YEARS, data: allFilesData[3].data + element.files })
                        } else if (ageBracket.BETWEEN_1_MONTH_AND_6_MONTHS == calculateAgeCategory(element.date)) {
                            !allFilesData[4]?.label ? (allFilesData[4] = { label: ageBracket.BETWEEN_1_MONTH_AND_6_MONTHS, data: 0 + element.files }) :
                                (allFilesData[4] = { label: ageBracket.BETWEEN_1_MONTH_AND_6_MONTHS, data: allFilesData[4].data + element.files })
                        } else {
                            !allFilesData[5]?.label ? (allFilesData[5] = { label: ageBracket.UNDER_1_MONTH, data: 0 + element.files }) :
                                (allFilesData[5] = { label: ageBracket.UNDER_1_MONTH, data: allFilesData[5].data + element.files })
                        }
                        // allFilesData.push({ label: `< ${element.age + 1}month`, data: formatBytes(element.files,2), percentage: parseFloat((element.files / sum) * 100).toFixed(2) })
                        // if (element.age == 0) {
                        //     // ***** less than 1 month
                        //     allFilesData.push({ label: '< 1month', data: element.files })
                        // } else if (element.age == 1) {
                        //     // ***** less than 6 month
                        //     allFilesData.push({ label: '< 6months', data: element.files })
                        // } else if (element.age > 1 && element.age < 5) {
                        //     // ***** greater than 1 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 1year');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 1year', data: element.files })
                        //     }
                        // } else if (element.age >= 5 && element.age < 8) {
                        //     // ***** greater than 8 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 5years');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 5years', data: element.files })
                        //     }
                        // } else if (element.age > 8) {
                        //     // ***** greater than 8 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 8years');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 8years', data: element.files })
                        //     }
                        // }
                        // if (element.age == 0) {
                        // ***** less than 1 month
                        //allFilesData.push({ label: `< ${element.age + 1}month`, data: formatBytes(element.files,2), percentage: parseFloat((element.files / sum) * 100).toFixed(2) })
                        // } 
                        // else if (element.age == 1) {
                        //     // ***** less than 6 month
                        //     allFilesData.push({ label: '< 6months', data: element.files })
                        // } else if (element.age > 1 && element.age < 5) {
                        //     // ***** greater than 1 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 1year');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 1year', data: element.files })
                        //     }
                        // } else if (element.age >= 5 && element.age < 8) {
                        //     // ***** greater than 8 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 5years');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 5years', data: element.files })
                        //     }
                        // } else if (element.age > 8) {
                        //     // ***** greater than 8 year
                        //     let _index = allFilesData.findIndex(e => e.label == '> 8years');
                        //     if (-1 != _index) {
                        //         allFilesData[_index].data = allFilesData[_index].data + element.files;
                        //     } else {
                        //         allFilesData.push({ label: '> 8years', data: element.files })
                        //     }
                        // }

                    });
                    allFilesData.forEach(e => {
                        e['percentage'] = parseFloat((e.data / totalFiles) * 100).toFixed(2);
                    })
                    allFilesData.reverse();
                    setFilesData(allFilesData);

                }
                // setServiceList(data.data.results);
            })
            .catch(error => {
                console.log(error)
            });
    }
    // React.useEffect(()=>{
    //     const formatDate = date => {
    //         return date.toISOString().split('T')[0];
    //                 }
    //         if(dateFilter){
    //         const currentDate = new Date()
    //         const currentYear = currentDate.getFullYear()
    //         const currentMonth = currentDate.getMonth() + 1

    //         if(dateFilter == "current_year"){
    //             setStartDate(formatDate(new Date(currentYear,0,1)));
    //             setEndDate(formatDate(new Date(currentYear,11,31)));
    //         } else if( dateFilter == "last_month"){
    //             let lastMonthYear , lastMonthNumber;
    //             if(currentMonth === 1){
    //                 lastMonthYear = currentYear -1;
    //                 lastMonthNumber = 12
    //             } else {
    //                 lastMonthYear = currentYear
    //                 lastMonthNumber = currentMonth -1
    //             }

    //             setStartDate( formatDate(new Date(lastMonthYear, lastMonthNumber -1 ,1)))
    //             setEndDate( formatDate(new Date(currentYear, currentMonth -1 ,0)))
    //         } else {
    //             console.log("dsdf")
    //         }


    //         }
    //             },[dateFilter])

    return (
        <div className={classes.mainContainer}>
            {/* {JSON.stringify(startDate)}
            {JSON.stringify(endDate)} */}
            <Box p={3}>
                <Grid container direction='column' spacing={2.3}>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Age of Files</Typography>
                            </Grid>
                            {/* <Grid item className={classes.closeIcon}>
                                <CloseIcon />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.secondHeading}>{totalStorage}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1} pb={3}>
                            <Grid item style={{ width: '24%', height: '23px', borderRadius: '8px', backgroundColor: '#007E23' }}>
                            </Grid>
                            <Grid item style={{ width: '20%', height: '23px', borderRadius: '8px', backgroundColor: '#38AA58' }}>
                            </Grid>
                            <Grid item style={{ width: '16%', height: '23px', borderRadius: '8px', backgroundColor: '#F4BF37' }}>
                            </Grid>
                            <Grid item style={{ width: '14%', height: '23px', borderRadius: '8px', backgroundColor: '#EE3F3F' }}>
                            </Grid>
                            <Grid item style={{ width: '10%', height: '23px', borderRadius: '8px', backgroundColor: '#C15959' }}>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" spacing={2}>

                            {filesData.map((file) => (
                                <Grid item key={file.label}>
                                    <Grid container direction='row' justifyContent="space-between">
                                        <Grid item>
                                            <Grid container direction='row' justifyContent="space-between" spacing={1} >
                                                <Grid item>
                                                    <div style={{ height: '22px', width: '22px', borderRadius: '50%', backgroundColor: '#007E23' }}></div>
                                                </Grid>
                                                <Grid item>
                                                    <Typography align="left" className={classes.years}>{file.label}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                                <Grid item><Typography align="left" className={classes.data}>{file.data} &nbsp; {file.percentage}%</Typography></Grid>
                                                {/* <Grid item><Typography align="left" className={classes.per}>50%</Typography></Grid> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}

                            {/* <Grid item>
                                <Grid container direction='row' justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <div style={{ height: '22px', width: '22px', borderRadius: '50%', backgroundColor: '#38AA58' }}></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography align='left' className={classes.years}>{`> 5years`}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item><Typography align="left" className={classes.data}>250</Typography></Grid>
                                            <Grid item><Typography align="left" className={classes.per}>35%</Typography></Grid>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <div style={{ height: '22px', width: '22px', borderRadius: '50%', backgroundColor: '#F4BF37' }}></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography className={classes.years} align='left'>{`> 1year`}</Typography>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item><Typography align="left" className={classes.data}>35</Typography></Grid>
                                            <Grid item><Typography align="left" className={classes.per}>7%</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <div style={{ height: '22px', width: '22px', borderRadius: '50%', backgroundColor: '#EE3F3F' }}></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography align='left' className={classes.years}>{`< 6months`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item><Typography align="left" className={classes.data}>15</Typography></Grid>
                                            <Grid item><Typography align="left" className={classes.per}>5%</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction='row' justifyContent="space-between">
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item>
                                                <div style={{ height: '22px', width: '22px', borderRadius: '50%', backgroundColor: '#C15959' }}></div>
                                            </Grid>
                                            <Grid item>
                                                <Typography align="left" className={classes.years}>{`< 1month`}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction='row' justifyContent="space-between" spacing={1}>
                                            <Grid item><Typography align="left" className={classes.data}>15</Typography></Grid>
                                            <Grid item><Typography align="left" className={classes.per}>2%</Typography></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
const useStyles = makeStyles({
    mainContainer: {
        background: "#4E5152 !important",
        minHeight: '565px',
        // maxHeight: '565px',
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    secondHeading: {
        fontWeight: 400,
        fontSize: '42px !important',
        lineHeight: '51px',
        color: '#FFFFFF',
    },
    years: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    data: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    per: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#697588'
    }
});