import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, Typography } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { makeStyles } from "@mui/styles";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';
import faker from 'faker';
import * as React from "react";
import { Bar } from 'react-chartjs-2';
import { ToggleButton } from "../home/home";
import { filetypes } from "../../global";
import axios from "axios";
import { useFeatures } from '../../views/FeaturesProvider/featuresProvider';
import { useEffect, useState } from 'react';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const DataType = () => {
    const classes = useStyles();
    const [date, setDate] = React.useState("month");
    const [filter, setFilter] = React.useState("");
    const features = useFeatures()
    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    const handleChange = (event, newAlignment) => {
        setDate(newAlignment);
    };
    const [data, setData] = React.useState(null);
    const [allData, setAllData] = React.useState([]);
    const [totalStorage, setTotalStorage] = React.useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [totalFetched, setTotalFetched] = useState(false);
    useEffect(() => {
        if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) {
            setIsAdmin(true);
            if (!totalFetched) {
                getFileType(true);
                setTotalFetched(true);
            }
        } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {
            setIsAdmin(false);
            if (!totalFetched) {
                getFileType(false);
                setTotalFetched(true);
            }
        }
    }, [features])
    // const labels = ['Docs', 'Images', 'WebPage', 'Video', 'Others'];
    const options = {
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 2,
            },
        },
        responsive: true,
        scales: {
            y: {
                ticks: { color: 'white', beginAtZero: true },
                gridLines: {
                    display: false
                }
            },

            x: {
                barThickness: 1,
                ticks: {
                    color: 'white',
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    callback: ((context, index) => {
                        // console.log(context);
                        return formatBytes(context)
                    })
                },
                gridLines: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                position: 'right',
                display: false

            },
            title: {
                display: false,
                text: 'Chart.js Bar Chart',
            },
            tooltip: {
                callbacks: {
                    label: (context, index) => {
                        // const label = context.dataset.label || '';
                        // const value = context.parsed.y;
                        return `${allData[context.dataIndex].files}`;
                    },
                },
            },
        },
    };
    const getFileType = (_isAdmin) => {
        let payload = {
            type: 'rep_filetype',
            "request": {
                "cid": 15,
                "type": "filetypes"
            }
        };
        axios.post(filetypes, payload)
            .then(response => {
                if (response?.status == 200) {
                    // let dynamicArray = []
                    // const dynamicObject = Object.values(response.data.result)
                    // dynamicObject.map(i =>
                    //     dynamicArray = i.data)
                    let dynamicArray;
                    let dynamicObject;
                    if (!_isAdmin) {
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i =>
                            dynamicArray = i.data)
                    } else {
                        dynamicArray = [];
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i => { if (i && i.data) { dynamicArray = dynamicArray.concat(i.data) } })

                    }
                    setAllData(dynamicArray);
                    let labels = dynamicArray?.map((e) => e.file_type);
                    let data = {
                        labels,
                        datasets: [
                            {

                                label: '',
                                // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                                data: dynamicArray?.map((e) => e.bytes),
                                borderWidth: 0,
                                categoryPercentage: 1,
                                backgroundColor: [
                                    // "rgba(189, 95, 95, 1) ",
                                    // "rgba(0, 126, 35, 1)",
                                    // "rgba(56, 170, 88, 1)",
                                    // "rgba(0, 126, 35, 1) ",
                                    "rgba(56, 170, 88, 1) "
                                ],
                                borderWidth: 1,
                                barPercentage: 0.4,
                            }

                        ],
                    };
                    setData(data);
                    const sum = dynamicArray?.reduce((accumulator, currentValue) => accumulator + currentValue.bytes, 0);
                    console.log("totalBytes", sum);
                    setTotalStorage(formatBytes(sum, 2));
                }
                // debugger
                // let allFilesData = [];
                // if (response?.data?.statusCode == 200) {
                //     response?.data?.body?.filetype?.forEach(element => {

                //         if (element.age == 0) {
                //             // ***** less than 1 month
                //             allFilesData.push({ label: '< 1month', data: element.files })
                //         } else if (element.age == 1) {
                //             // ***** less than 6 month
                //             allFilesData.push({ label: '< 6months', data: element.files })
                //         } else if (element.age > 1 && element.age < 5) {
                //             // ***** greater than 1 year
                //             let _index = allFilesData.findIndex(e => e.label == '> 1year');
                //             if (-1 != _index) {
                //                 allFilesData[_index].data = allFilesData[_index].data + element.files;
                //             } else {
                //                 allFilesData.push({ label: '> 1year', data: element.files })
                //             }
                //         } else if (element.age >= 5 && element.age < 8) {
                //             // ***** greater than 8 year
                //             let _index = allFilesData.findIndex(e => e.label == '> 5years');
                //             if (-1 != _index) {
                //                 allFilesData[_index].data = allFilesData[_index].data + element.files;
                //             } else {
                //                 allFilesData.push({ label: '> 5years', data: element.files })
                //             }
                //         } else if (element.age > 8) {
                //             // ***** greater than 8 year
                //             let _index = allFilesData.findIndex(e => e.label == '> 8years');
                //             if (-1 != _index) {
                //                 allFilesData[_index].data = allFilesData[_index].data + element.files;
                //             } else {
                //                 allFilesData.push({ label: '> 8years', data: element.files })
                //             }
                //         }

                //     });
                //     allFilesData.reverse();
                //     setFilesData(allFilesData);
                // }
                // setServiceList(data.data.results);
            })
            .catch(error => {
                console.log(error)
            });
    }
    return (
        <div className={classes.mainContainer}>
            <Box p={3}>
                <Grid container direction='column' spacing={2.3}>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.mainHeading} style={{ fontWeight: 700, fontSize: '20px' }}>Data Type</Typography>
                            </Grid>
                            {/* <Grid item className={classes.closeIcon}>
                                <CloseIcon />
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1} sx={{ alignItems: 'center' }}>
                            {/* <Grid item className={classes.toggleContainer}>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={date}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label="Platform"
                                >
                                    <ToggleButton value="month" className="h-40">Month</ToggleButton>
                                    <ToggleButton value="year" className="h-40">Year</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid> */}
                            {/* <Grid item>
                                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                                    <InputLabel id="demo-select-small-label">Bar Graph</InputLabel>
                                    <Select
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={filter}
                                        label="Age"
                                        onChange={handleChangeFilter}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={10}>Ten</MenuItem>
                                        <MenuItem value={20}>Twenty</MenuItem>
                                        <MenuItem value={30}>Thirty</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justifyContent='space-between' spacing={1}>
                            <Grid item>
                                <Typography align='left' className={classes.secondHeading}>{totalStorage}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item p={0} style={{ maxWidth: '100%' }}>
                        {data ? <Bar options={options} data={data} /> : ''}
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
const useStyles = makeStyles({
    mainContainer: {
        background: "#4E5152 !important",
        // height:'630px',
        // height: '466px',
        minHeight: '565px',
        maxHeight: '565px',
        // width: '698px',
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',
        maxWidth: '100%'
    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    secondHeading: {
        fontWeight: 400,
        fontSize: '42px !important',
        lineHeight: '51px',
        color: '#FFFFFF',
    },
    years: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    data: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    per: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#697588'
    }
});