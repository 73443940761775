import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import { InputBase } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { drawerPaddingRight, drawerWidth } from '../navbar/navbar';
import { ButtonColor, PrimaryColor } from '../theme/theme';
import { Grid, Paper, Typography, Card } from "@mui/material";
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";

import { makeStyles } from "@mui/styles";

import "./header.css";
import Box from "@mui/material/Box";
import { useLocation, useMatch } from 'react-router';
import { useMemo, useEffect } from 'react';
import { width } from '@mui/system';
import axios from "axios";
//import { env } from "../../env";
import { removeLoginUser } from "../../LocalStorageService";
import moment from "moment";
import { Link } from 'react-router-dom';
import { getApiUrl } from "./../../global";
import { StyledButton, StyledButtonContained, StyledButtonOutlined } from '../../views/Admin/AccountCreation/accountCreation';

const BorderDiv = styled('div')({
    position: 'absolute',
    width: drawerWidth,
    height: '100%',
    borderBottom: 'none',
    zIndex: 2,
    paddingRight: drawerPaddingRight
});
const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#49484E' : 'rgba(247, 247, 249, 0.5)',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 400,
    color: theme.palette.text.primary,
}));
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));



export const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showAllNotification, setShowAllNotification] = React.useState(true);
    const [notificationData, setNotificationData] = React.useState(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [filter, setFilter] = React.useState("");
    const [date, setDate] = React.useState("month");

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    }
    const handleChange = (event, newAlignment) => {
        setDate(newAlignment);
    };
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        color: '#fff',
        textAlign: 'left',


    }));

    async function getNotification() {
        let url = await getApiUrl();

        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/notifications/";
        axios
            .get(apiUrl)
            .then(data => {
                console.log("Notification Data", data);
                data?.data?.results?.notifications?.reverse();
                setNotificationData(data.data);
            }).catch(error => console.log(error));
    }
    useEffect(() => {
        try {
            getNotification();
            // const intervalCall = setInterval(() => {
            //     getNotification();
            // }, 5000);
            return () => {
                // clean up
                // clearInterval(intervalCall);
            };
        } catch (e) {
            console.log(e);
        }
    }, []);
    const logOut = () => {
        removeLoginUser();

    }
    const markAsUnread = async (notification) => {
        let url = await getApiUrl();

        let notificationReadApiUrl = url + process.env.REACT_APP_API_BASE_URL + "/notifications/" + notification.uuid + "/";
        axios.patch(notificationReadApiUrl, {
            "read": true
        })
            .then(response => {
                getNotification();
                // if(notification.type== "pre-determined"){
                //     this.props.history.push(`/orderTracking/${notification.uuid}`)
                // }
            }).catch(error => console.error(error));
    }

    // To Determine the Title which appears on the AppBar
    const location = useLocation();
    const isRouteActive = useMemo(() => {
        return (routes) => {
            return routes.some((route) => location.pathname.split('/')[1] === route);
        };
    }, [location.pathname]);

    const navItems = [
        { label: 'Home', iconUrl: '/assets/img/home.png', highlightIconUrl: '/assets/img/homeHighlight.png', to: '/', viewUrls: ['', 'Home'] },
        { label: 'Orders', iconUrl: '/assets/img/order.png', highlightIconUrl: '/assets/img/orderHighlight.png', to: '/Orders', viewUrls: ['Orders', 'OrderTracking', 'CustomerOrders'] },
        { label: 'My Team', iconUrl: '/assets/img/account.png', highlightIconUrl: '/assets/img/accountHighlight.png', to: '/Team', viewUrls: ['Team'] },
        { label: 'Settings', iconUrl: '/assets/img/settings.png', highlightIconUrl: '/assets/img/settingsHighlight.png', to: '/Settings', viewUrls: ['Settings'] },
        { label: 'Restore', iconUrl: '/assets/img/restore.png', highlightIconUrl: '/assets/img/restore.png', to: '/Restore', viewUrls: ['Restore'] }
    ];

    return (
        <>
            <AppBar position="fixed" sx={{ width: '100%', backgroundColor: PrimaryColor, borderBottom: 'none', boxShadow: '0px 4px 11px 0px #0000001A' }}>
                <BorderDiv />
                <Toolbar sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
                    <Box display='inline-flex' gap={4} className='col-md-2'>
                        <Link to='/' passHref style={{ textDecoration: 'none', zIndex: 2 }}>
                            <img width={'40px'}
                                height={'40px'}
                                alt="tapeark"
                                src={process.env.PUBLIC_URL + '/assets/img/tapeark_logo_inv.svg'} />
                        </Link>
                        <Typography sx={{ fontFamily: 'Lato', fontWeight: 700, fontSize: 28, lineHeight: '33px', letterSpacing: '0em' }}>
                            {navItems.find((item) => isRouteActive(item.viewUrls))?.label}
                        </Typography>
                    </Box>
                    <div className='col-md-10'>
                        {/* Seach bar */}
                        {/* <InputBase
                            type="text"
                            placeholder="Search"
                            startAdornment={
                                <InputAdornment position="start" sx={{ color: '#3A3E3F' }} >
                                    <SearchIcon sx={{ marginRight: '4px' }} />
                                </InputAdornment>
                            }
                            sx={{
                                '&.MuiInputBase-input': {
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 400,
                                    lineHeight: '15px',
                                    letterSpacing: '0em',
                                    textAlign: 'left',
                                    color: '#3A3E3F',

                                },
                                width: '241px',
                                height: '41px',
                                background: '#E9EEF44D',
                                color: '#3A3E3F',
                                borderRadius: '20px',
                                paddingLeft: '20px',
                                border: 'none',
                                outline: 'none',
                            }}

                        /> */}
                        {/* Notification Icon */}
                        {/* <IconButton sx={{
                            paddingLeft: '12px',
                            cursor: 'pointer'
                        }} aria-label="notifications" id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}>
                            <Badge badgeContent={notificationData?.results?.unread_count} color="primary">
                                <NotificationsOutlinedIcon sx={{ color: '#FFC540' }} />
                            </Badge>
                        </IconButton> */}
                        <Menu
                            className='bg-color'
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                style: {
                                    width: 587,
                                    height: "max-content",
                                    maxHeight: 524,
                                    left: 895,

                                },
                            }}
                        >
                            <Box sx={{ backgroundColor: "#3A3E3F" }}>
                                <Grid p={2} spacing={1}>
                                    <Grid marginLeft={0} container spacing={1} alignItems="center" className='no-box' boxShadow="none">
                                        {
                                            notificationData?.results?.notifications?.length ?
                                                <>
                                                    <Grid item xs={6}>
                                                        <Item className='heading-notify'>Your Notifications</Item>
                                                    </Grid>
                                                    <Grid item xs={6} paddingBottom={2} direction="row" display="flex" justifyContent="flex-end">
                                                        <Item><StyledButtonOutlined sx={{
                                                            cursor: 'pointer'
                                                        }} variant="outlined" className='clr-btn' onClick={() => setNotificationData([])}>Clear</StyledButtonOutlined></Item>
                                                    </Grid>
                                                </> :
                                                <Grid item xs={6}>
                                                    <Item className='heading-notify'>No Notifications</Item>
                                                </Grid>
                                        }
                                    </Grid>
                                    {
                                        notificationData?.results?.notifications?.length ?
                                            <hr className='headerHr' /> : ""
                                    }

                                </Grid>
                                {notificationData?.results?.notifications?.length ?
                                    <>
                                        <Grid direction='row' p={2} spacing={1}>
                                            <Button variant={showAllNotification ? "outlined" : null} className={showAllNotification ? 'activeFilterButton' : 'disableFilterButton'} onClick={() => setShowAllNotification(true)} sx={{
                                                cursor: 'pointer'
                                            }}>
                                                All
                                            </Button>
                                            <Button variant={!showAllNotification ? "outlined" : null} className={!showAllNotification ? 'activeFilterButton' : 'disableFilterButton'} onClick={() => setShowAllNotification(false)} sx={{
                                                cursor: 'pointer'
                                            }}>Unread</Button>
                                        </Grid>
                                        <Grid container marginLeft={0} paddingBottom={2} spacing={1} p={2} marginTop={3} alignItems="center" className='no-box bg-box' >
                                            {notificationData?.results?.notifications?.map((noti, i) => {

                                                return (
                                                    ((showAllNotification) || (!showAllNotification && !noti?.read)) &&
                                                    <>
                                                        <Link to={`/OrderTracking/${noti.uuid}`} passHref state={{ data: noti }} style={{ width: '100%', textDecoration: 'none', color: 'transparent' }}>
                                                            <Grid key={i} container direction="row" onClick={() => markAsUnread(noti)}
                                                                className={noti?.read ? 'readNotification' : null} sx={{
                                                                    cursor: 'pointer'
                                                                }}>
                                                                <Grid item xs={8} marginBottom={3} >
                                                                    <MenuItem>
                                                                        <ListItemText className='list-item'>{noti.description}</ListItemText>
                                                                    </MenuItem>
                                                                    <p className='time-txt' p="0">{moment(noti.created_at).format("ddd MMM/DD/YYYY, hh:mm A")}</p>
                                                                </Grid>
                                                                {!noti?.read && <Grid item xs={4} direction="row" display="flex" justifyContent="flex-end" alignItems="center">
                                                                    <img src="/assets/img/circledot.svg" alt="" height={15} />
                                                                </Grid>}

                                                            </Grid >
                                                        </Link>
                                                        <hr className='width-hr' />
                                                    </>
                                                )

                                            })
                                            }


                                            {/* <Grid item xs={8} marginLeft={0} marginTop={1} paddingTop={0} >
                                        <MenuItem>
                                            <ListItemText className='list-item'>You have a request from the team</ListItemText>
                                        </MenuItem>
                                        <p className='time-txt' p="0">Today 4:14 pm</p>
                                    </Grid>
                                    <Grid item xs={4} direction="row" display="flex" justifyContent="flex-end">
                                        <img src="/assets/img/circledot.svg" alt="" />
                                    </Grid>
                                    <StyledPaper
                                        sx={{
                                            my: 1,
                                            mx: 'auto',
                                            p: 2,
                                            maxWidth: 500,
                                        }}
                                    >
                                        <Grid container wrap="nowrap" className='width-notify' marginLeft={0}>
                                            <Grid item xs>
                                                <Typography fontSize={14} color={"#49484E"} >Hi, we see that some of the tapes you sent are encrypted. We need a tape list and the encryption keys to continue the ingestion process</Typography>
                                            </Grid>
                                        </Grid>
                                    </StyledPaper>
                                    <Grid item xs={12} direction="row" display="flex" justifyContent="flex-end">
                                        <Item><Button variant="outlined" className='upload-btn'>Upload Now</Button></Item>
                                    </Grid> */}
                                        </Grid>
                                    </> : ""}
                                {/* <Grid container marginLeft={0} paddingBottom={2} p={2} paddingTop={2} spacing={1} alignItems="center" className='no-box' >

                                    <Grid item xs={8}>
                                        <MenuItem>
                                            <ListItemText className='list-item'>Your tapes have reached the destination</ListItemText>
                                        </MenuItem>
                                        <p className='time-txt' p="0">Today 4:14 pm</p>
                                    </Grid>

                                </Grid> */}

                            </Box>
                        </Menu>
                        {/* Avatar */}
                        {/* <Button>
                            <Avatar alt="User Avatar" src={'/assets/img/dummyprofile.png'} sx={{ width: 32, height: 32 }}></Avatar>
                        </Button> */}
                        <Link to='/' passHref style={{ textDecoration: 'none' }}>
                            <StyledButtonContained variant='contained' sx={{
                                height: '40px',
                                cursor: 'pointer'
                            }}
                                onClick={logOut}>
                                Logout
                            </StyledButtonContained>
                        </Link>
                    </div>
                </Toolbar>
            </AppBar >
        </>
    )
}
export const ToggleButton = styled(MuiToggleButton)({
    "&.MuiToggleButton-root.Mui-selected": {
        backgroundColor: ButtonColor,
        // borderRadius: "8px",
        color: '#404558',
        fontWeight: 600,
        fontSize: "14px",
        border: `1px solid #000`,
    },
    "&.MuiToggleButton-root": {
        // color: "#404558",
        borderRadius: "8px",
        textTransform: "none !important",
        height: "35px",
        fontSize: "14px",
        width: "116px",
        border: "0 !important",
        // border-radius: 8px;
        background: "#E1E8F080",
        color: "#404558"
    },
});

const useStyles = makeStyles({
    mainContainer: {
        background: "#4E5152 !important",
        minHeight: '565px',
        maxHeight: '565px',
        borderRadius: 8,
        border: '1px solid #4a5058',
        background: '#3A3E3F',

    },
    mainHeading: {
        fontSize: '20px',
        fontWeight: 700,
        lineHeight: '24px',
        color: '#FFFFFF'
    },
    closeIcon: {
        color: '#969696'
    },
    secondHeading: {
        fontWeight: 400,
        fontSize: '42px !important',
        lineHeight: '51px',
        color: '#FFFFFF',
    },
    years: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    data: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#FFFFFF'
    },
    per: {
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '17px',
        color: '#697588'
    }
});