import axios from "axios";
import { getLocalConfig, setLocalConfig, removeLocalConfig } from "./LocalStorageService";
export const getConfigInfo = async () => {
    try {
        const res = await axios.get('/env/config.json');
        setLocalConfig(res.data);
        return res.data.apiUrl;
    } catch (error) {
        console.error(error);
    }
};
window.addEventListener("beforeunload", (event) => {
    removeLocalConfig();
    // getConfigInfo();
    console.log("API call before page reload");
});
window.addEventListener("unload", (event) => {
    // getConfigInfo();
    console.log("API call after page reload");
});

export const getApiUrl = () => {
    // return getConfigInfo();
    if (!getLocalConfig()) {
        // return getConfigInfo();
        return process.env.REACT_APP_API_ENDPOINT;
    } else {
        // return getLocalConfig().apiUrl;
        return process.env.REACT_APP_API_ENDPOINT;
    }
    // return getLocalConfig().apiUrl;

    // let _url = "";
    // if (url == 'getUserFeatureApiUrl') {
    //     _url = apiUrl + `${process.env.REACT_APP_API_BASE_URL}` + `/auth/user/`;
    // } else if (url == 'getUsloginApiUrlerFeatureApiUrl') {
    //     _url = apiUrl + process.env.REACT_APP_API_BASE_URL + "/auth/login/";
    // }else if (url == 'verifyOtpApiUrl') {
    //     _url = apiUrl + process.env.REACT_APP_API_BASE_URL + "/auth/verifyotp/";
    // }else if (url == 'generateotpApiUrl') {
    //     _url = apiUrl + process.env.REACT_APP_API_BASE_URL + "/auth/generateotp/";
    // }
    // export const forgotPasswordApiUrl = apiUrl + process.env.REACT_APP_API_BASE_URL + "/auth/forgotpassword/";
}

// export const ageing = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'ageing';
// export const filetypes = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'filetypes';
// export const formats = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'formats';
// export const ingested = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'ingested-timeline';
// export const items = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'items';
// export const policies = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'policies';
// export const regions = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'regions';
// export const servers = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'servers';
// export const test = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'test';
// export const totals = `${process.env.REACT_APP_GRAPH_ENDPOINT}` + 'totals';

export const ageing = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const filetypes = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const formats = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const ingested = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const items = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const policies = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const regions = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const servers = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const test = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const totals = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
export const addArkBridgeJobId = `${process.env.REACT_APP_API_ENDPOINT}` + `${process.env.REACT_APP_API_BASE_URL}` + '/dashboard/';
