import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useEffect } from "react";

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Image from '../../assets/img/bg-login.png'; // Import using relative path
import axios from "axios";
//import { env } from "../../env";
import { useNavigate } from "react-router-dom";
import { setLoginUser } from '../../LocalStorageService';
import { getApiUrl } from '../../global';
import {
    Dialog, FormControl, InputLabel, Select, Radio, RadioGroup,
    FormLabel, FormGroup, DialogContent, IconButton, InputAdornment, formLabelClasses
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import OtpInput from 'react-otp-input';
import React, { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { findAllByDisplayValue } from '@testing-library/react';
import { CustomOTPInput } from './otp';
import { useFeatures } from '../FeaturesProvider/featuresProvider';
import { StyledButtonContained } from '../Admin/AccountCreation/accountCreation';


const styles = {
    paperContainer: {
        height: '100vh',
        backgroundImage: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
};

export const Landing = () => {
    const classes = useStyles();
    const [otp, setOtp] = useState('');
    const [otpForgotPassword, setOtpForgotPassword] = useState('');
    const [otpForgotPasswordTwo, setOtpForgotPasswordTwo] = useState('');
    const [emailForgotPassword, setEmailForgotPassword] = useState('');
    const [userMFAOption, setUserMFAOption] = useState('');
    const [authenticatorImage, setAuthenticatorImage] = useState("/assets/img/QR_code.jpg");
    const { setFeatureList } = useFeatures();

    const [visibleState, setVisibleState] = useState({
        login: true,
        enterOtpMail: false,
        loginFailed: false,
        authenticator: false,
        authenticatorForgotPassword: false,
        forgetPassword: false,
        emailSentWithPassword: false,
        enterOtpMailForgotPassword: false,
        enterChangePasswordForgotPassword: false,
        enterOtpMailForgotPasswordTwo: false
    });
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: "transparent",
        border: "none",
        boxShadow: "none",
        color: '#fff',
        textAlign: 'left',
    }));

    const navigate = useNavigate()
    const navigateHome = () => navigate('/Home');
    const { register, handleSubmit, getValues, watch, setValue, formState: {
        errors
    } } = useForm()
    useEffect(() => {
        setOtp('');
        setOtpForgotPassword('');
        setOtpForgotPasswordTwo('');
    }, [visibleState?.login, visibleState?.enterOtpMail, visibleState?.enterOtpMailForgotPassword, visibleState?.enterOtpMailForgotPasswordTwo]);
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const onSubmit = (data) => console.log(data)
    const onLoginSubmit = async (data) => {
        let url = await getApiUrl();
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/login/";
        // event.preventDefault();
        // const data = new FormData(event.currentTarget);
        let payLoad = {
            // "username": data.username,
            "email": data.username,
            "password": data.password
        }
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        axios({
            method: 'post',
            url: apiUrl,
            data: payLoad,
        }).then(function (response) {
            console.log("Login Response", response);
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    setUserMFAOption(response.data.two_step_verification);
                    if (response.data.two_step_verification == "authenticator" && !response.data.is_authenticator_reg) {
                        setAuthenticatorImage(response.data.qr_picture_url);
                        setVisiblity('authenticator');
                    } else {
                        setVisiblity('enterOtpMail');
                    }
                } else if (response?.data?.access) {
                    setLoginUser(response.data);
                    setFeatureList(response.data.user.features)
                    navigateHome();
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    const onLoginSubmit_is_authenticator_reg = async () => {
        let url = await getApiUrl();
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/login/";
        // event.preventDefault();
        const data = getValues();
        let payLoad = {
            // "username": data.username,
            "email": data.username,
            "password": data.password
        }
        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });
        axios({
            method: 'post',
            url: apiUrl,
            data: payLoad,
        }).then(function (response) {
            console.log("Login Response", response);
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    setUserMFAOption(response.data.two_step_verification);
                    if (response.data.two_step_verification == "authenticator") {
                        setAuthenticatorImage(response.data.qr_picture_url);
                        setVisiblity('authenticator');
                    } else {
                        setVisiblity('enterOtpMail');
                    }
                } else if (response?.data?.access) {
                    setLoginUser(response.data);
                    setFeatureList(response.data.user.features)
                    navigateHome();
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    const resendOtp = async () => {
        let url = await getApiUrl();
        const apiUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/login/";
        const data = getValues();
        let payLoad = {
            // "username": data.username,
            "email": data.username,
            "password": data.password
        }
        axios({
            method: 'post',
            url: apiUrl,
            data: payLoad,
        }).then(function (response) {
            console.log("Login Response", response);
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    setVisiblity('enterOtpMail');
                }

            }
        }).catch(function (error) {
            console.log(error);
        });
    };
    const submitOtp = async () => {
        let url = await getApiUrl();

        let verifyOtpUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/verifylogin/";
        let formValues = getValues();
        let payLoad = {
            "email": formValues.username,
            "password": formValues.password,
            "otp": Number(otp)
        }
        axios({
            method: 'post',
            url: verifyOtpUrl,
            data: payLoad,
        }).then(function (response) {
            console.log("Login Response", response);
            localStorage.setItem("login-tapeArk", JSON.stringify(response));
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response?.data?.access) {
                    setLoginUser(response.data);
                    setFeatureList(response.data.user.features)
                    navigateHome();
                } else {
                    // ***** Show Pop Up
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const verifyForgotPassword = async () => {
        let url = await getApiUrl();
        let verifyOtpUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/verifyotp/";
        let formValues = getValues();
        let payLoad = {
            "email": emailForgotPassword ? emailForgotPassword : document?.getElementById("emailForgotPassword")?.value,
            "otp": Number(otpForgotPassword)
        }
        axios({
            method: 'post',
            url: verifyOtpUrl,
            data: payLoad,
        }).then(function (response) {
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP verified successfully.") {
                    setVisiblity('enterChangePasswordForgotPassword');
                } else {
                    // ***** Show Pop Up

                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const generateForgotPasswordTwo = async () => {
        let url = await getApiUrl();
        let generateotpURL = url + process.env.REACT_APP_API_BASE_URL + "/auth/generateotp/";
        let payLoad = {
            "email": emailForgotPassword ? emailForgotPassword : document?.getElementById("emailForgotPassword")?.value,
        }
        axios({
            method: 'post',
            url: generateotpURL,
            data: payLoad,
        }).then(function (response) {
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    // if ((response.data.two_step_verification == "authenticator" && !response.data.is_authenticator_reg)) {
                    //     setEmailForgotPassword(payLoad.email);
                    //     setAuthenticatorImage(response.data.qr_picture_url);
                    //     setVisiblity('authenticatorForgotPassword');
                    // } else {
                    setEmailForgotPassword(payLoad.email);
                    setVisiblity('enterOtpMailForgotPasswordTwo');
                    // }
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const submitForgotPassword = async () => {
        let url = await getApiUrl();
        let generateotpURL = url + process.env.REACT_APP_API_BASE_URL + "/auth/generateotp/";
        let _emailForgotPassword = getValues("emailForgotPassword");

        if (document?.getElementById("emailForgotPassword")?.value)
            document.getElementById("emailForgotPassword").value = _emailForgotPassword ? _emailForgotPassword : document?.getElementById("emailForgotPassword")?.value;
        let payLoad = {
            "email": _emailForgotPassword,
        }
        axios({
            method: 'post',
            url: generateotpURL,
            data: payLoad,
        }).then(function (response) {
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    setUserMFAOption(response.data.two_step_verification)
                    if ((response.data.two_step_verification == "authenticator" && !response.data.is_authenticator_reg)) {
                        setEmailForgotPassword(payLoad.email);
                        setAuthenticatorImage(response.data.qr_picture_url);
                        setVisiblity('authenticatorForgotPassword');
                    } else {
                        setEmailForgotPassword(payLoad.email);
                        gotoEnterOTPForgotPassword();
                    }
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const submitForgotPasswordBypass_is_authenticator_reg = async () => {
        let url = await getApiUrl();
        let generateotpURL = url + process.env.REACT_APP_API_BASE_URL + "/auth/generateotp/";
        let payLoad = {
            "email": emailForgotPassword ? emailForgotPassword : document?.getElementById("emailForgotPassword")?.value,
        }
        axios({
            method: 'post',
            url: generateotpURL,
            data: payLoad,
        }).then(function (response) {
            // ***** If success redirect to home, On fail present error message and do nothing.
            if (response.status == 200) {
                if (response.data.message == "OTP was sent successfully.") {
                    setUserMFAOption(response.data.two_step_verification);
                    if (response.data.two_step_verification == "authenticator") {
                        setEmailForgotPassword(payLoad.email);
                        setAuthenticatorImage(response.data.qr_picture_url);
                        setVisiblity('authenticatorForgotPassword');
                    } else {
                        setEmailForgotPassword(payLoad.email);
                        gotoEnterOTPForgotPassword();
                    }
                }
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const gotoEnterOTPForgotPassword = () => {
        let visiblity = {
            login: false,
            enterOtpMail: false,
            loginFailed: false,
            authenticator: false,
            forgetPassword: false,
            emailSentWithPassword: false,
            enterOtpMailForgotPassword: true,
            enterChangePasswordForgotPassword: false,
        }
        setVisibleState(visiblity);
    }

    const onChangePasswordSubmit = async (data) => {
        generateForgotPasswordTwo();
    };
    const changePassword = async () => {
        let url = await getApiUrl();

        let verifyOtpUrl = url + process.env.REACT_APP_API_BASE_URL + "/auth/forgotpassword/";
        let formValues = getValues();
        let payLoad = {
            "email": emailForgotPassword,
            "new_password1": formValues.password1,
            "new_password2": formValues.password2,
            "otp": Number(otpForgotPasswordTwo)
        }
        axios({
            method: 'post',
            url: verifyOtpUrl,
            data: payLoad,
        }).then(function (response) {
            // ***** If success redirect show pop up
            if (response.status == 200) {
                setVisiblity('emailSentWithPassword');
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    const setVisiblity = (type, byPassAuthStatus = false) => {
        let visiblity = {
            login: type == 'login' ? true : false,
            enterOtpMail: type == 'enterOtpMail' ? true : false,
            loginFailed: type == 'loginFailed' ? true : false,
            authenticator: type == 'authenticator' ? true : false,
            authenticatorForgotPassword: type == 'authenticatorForgotPassword' ? true : false,
            forgetPassword: type == 'forgetPassword' ? true : false,
            emailSentWithPassword: type == 'emailSentWithPassword' ? true : false,
            enterOtpMailForgotPassword: type == 'enterOtpMailForgotPassword' ? true : false,
            enterChangePasswordForgotPassword: type == 'enterChangePasswordForgotPassword' ? true : false,
            enterOtpMailForgotPasswordTwo: type == 'enterOtpMailForgotPasswordTwo' ? true : false,
        }
        setVisibleState(visiblity);
    }
    const showForgetPassword = () => {
        const data = getValues();
        if (data?.username) {
            setEmailForgotPassword(data?.username);
            setValue('emailForgotPassword', data?.username);
        } else {
            setEmailForgotPassword("");
            setValue('emailForgotPassword', "");

        }
        setVisiblity('forgetPassword');
        return false;
    }
    return (
        <Paper style={styles.paperContainer}>

            <Container component="main" display="flex" className={classes.mainContainer} >
                <CssBaseline />
                <Grid container height={"500px"} width={"900px"}>

                    <Grid item xs={6} className={classes.imgContainer} style={{
                        // backgroundImage: `url("/assets/img/bg-cover.svg")`
                        background: '#c82026',
                        paddingTop: '0px !important'
                    }}>
                        <Item style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <img src="/assets/img/tape-ark-logo.png" alt="" style={{ width: 'auto', height: '200px' }} />
                            <Typography className={classes.heading} style={{ color: 'white', fontSize: '28px', textAlign: 'center', fontFamily: 'poppins' }}>Welcome to the <br /> #NoMoreTape <br /> Revolution</Typography>
                            <Typography className={classes.heading} style={{ color: 'white', marginTop: '50px', fontFamily: 'poppins' }}>Instant access to your legacy data</Typography>
                            {/* <Typography style={{ fontSize: '24px', color: '#4B4B4D', fontWeight: '700', marginTop: '6px' }}>Tape to Cloud Migration</Typography> */}
                        </Item>
                    </Grid>
                    {/* ***** Login Form */}
                    {visibleState?.login && <Grid item xs={6} className={classes.loginContainer} style={{ background: " #3A3E3F", padding: "35px" }}>
                        <form onSubmit={handleSubmit(onLoginSubmit)}>
                            {/* <Item> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >
                                <img src="/assets/img/Tape-Ark-Nexus-Horizontal-White.png" alt="" style={{ width: 'auto', height: '80px', marginLeft: 'auto' }} />
                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px'
                                }}>
                                    Welcome Back!
                                </Typography>
                                <Typography className={classes.heading} style={{ color: 'white', textAlign: 'left', fontFamily: 'poppins' }}>Don't have an account?<br /> Contact nexus@tapeark.com</Typography>
                                <Box noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        type='email'
                                        // id="username"
                                        label="Username"
                                        name="username"
                                        {...register("username", {
                                            required: true,
                                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                        })}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#C7CCD0',
                                                }
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                //fontFamily: 'Poppins', 
                                                //fontWeight: 400, 
                                                //lineHeight: '33px', 
                                                //fontSize: '22px', 
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                '&.Mui-focused': {
                                                    color: '#FFC540'
                                                },
                                            },
                                        }}
                                    />
                                    {/* {errors.username && <p>Please check the Email</p>} */}

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        // id="password"
                                        {...register("password", { required: true })}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#C7CCD0',
                                                }
                                            },
                                            '& .MuiOutlinedInput-input': {

                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                '&.Mui-focused': {
                                                    color: '#FFC540'
                                                },
                                            },
                                        }}

                                    />
                                    <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                        <a href="#" onClick={() => { showForgetPassword() }} style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }}>Forgot Password ?</a>
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, gap: '15px', width: '100%' }}>

                                        <StyledButtonContained variant="contained"
                                            type="submit"
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Login
                                        </StyledButtonContained>
                                    </Box>
                                </Box>
                            </Box>
                            {/* </Item> */}
                        </form>
                    </Grid>}

                    {/* ***** Enter OTP Mail */}
                    {visibleState?.enterOtpMail &&
                        <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >
                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px'
                                }}>
                                    Enter {userMFAOption != 'authenticator' ? 'OTP' : 'MFA'}
                                </Typography>

                                <Typography style={{ fontSize: "14px", color: "#C7CCD0", fontWeight: "400" }}>An MFA Code has been sent to your {userMFAOption}. </Typography>
                                <Box noValidate sx={{
                                    width: '100%',
                                    mt: 5,
                                    display: 'flex',
                                    alignItems: 'start',
                                    justifyContent: 'start',
                                    flexDirection: 'column'
                                }}>
                                    <OtpInput alignItems="start"
                                        value={otp}
                                        // onInput={(event) => {
                                        //     // event?.preventdefault();
                                        //     setOtp(event.target.value);
                                        // }}
                                        onChange={setOtp}
                                        numInputs={6}
                                        // shouldAutoFocus={true}
                                        renderSeparator={<span style={{
                                            fontSize: "22px",
                                            marginLeft: "2%",
                                            marginRight: "2%",
                                        }} ></span>}
                                        renderInput={(props) => <input {...props} type="text" />}
                                        inputType={'text'}
                                        inputStyle={{
                                            width: "20%",
                                            marginBottom: "10px",
                                            height: "70px",
                                            borderRadius: "8px",
                                            border: "1px solid var(--Gray-1, #C7CCD0)",
                                            background: "var(--Charcoal, #3A3E3F)",
                                            outline: "none",
                                            color: "#fff",
                                            fontSize: "22px",
                                            fontWeight: "400"

                                        }}
                                    />

                                    <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                        {
                                            userMFAOption == 'authenticator' ?
                                                <>
                                                    <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => onLoginSubmit_is_authenticator_reg()}>Scan QR</a> or&nbsp;
                                                </> : ''
                                        }{
                                            userMFAOption != 'authenticator' ?
                                                <>
                                                    <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={resendOtp}>Resend OTP</a> or&nbsp;
                                                </> :
                                                ''
                                        } <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => { setVisiblity('login') }}>Change Email</a>
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5, gap: '15px', width: '100%' }}>
                                        <StyledButtonContained variant="contained"
                                            onClick={submitOtp}
                                            type="button"
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Login
                                        </StyledButtonContained>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    }

                    {/* ***** Login Failed Message */}
                    {visibleState?.loginFailed && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >
                                <Item>
                                    <img src="/assets/img/alert-circle.svg" alt="" />
                                </Item>
                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px',
                                    marginTop: '12px',
                                }}>
                                    Login Failed
                                </Typography>

                                <Typography style={{ fontSize: "20px", color: "#C7CCD0", fontWeight: "400" }}>Incorrect Username or password</Typography>
                                <Box onSubmit={handleSubmit} noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: '15px', width: '100%' }}>

                                        <StyledButtonContained variant="contained"
                                            type="submit"
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Retry
                                        </StyledButtonContained>
                                    </Box>
                                    <Typography style={{ textAlign: "center", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                        <a href="" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }}>Forgot Password ?</a>
                                    </Typography>
                                </Box>
                            </Box>
                        </Item>
                    </Grid>}

                    {/* ***** Authenticator */}
                    {visibleState?.authenticator && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >

                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px',
                                    marginTop: '12px',
                                    textAlign: "center",
                                    color: "#FFC540",
                                    marginBottom: "30px"
                                }}>
                                    Scan this QR on your authenticator App
                                </Typography>
                                <Item>
                                    <img src={authenticatorImage} alt="" style={{ height: '200px', width: '200px' }} />
                                </Item>

                                <Box onSubmit={handleSubmit} noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 3, gap: '15px', width: '100%' }}>

                                        <StyledButtonContained variant="contained"
                                            type="button"
                                            onClick={() => { setVisiblity('login') }}
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Cancel
                                        </StyledButtonContained>
                                        <StyledButtonContained variant="contained"
                                            type="button"
                                            onClick={() => { setVisiblity('enterOtpMail'); }}
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Next
                                        </StyledButtonContained>
                                    </Box>

                                </Box>
                            </Box>
                        </Item>
                    </Grid>}

                    {/* ***** Authenticator */}
                    {visibleState?.authenticatorForgotPassword && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >

                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px',
                                    marginTop: '12px',
                                    textAlign: "center",
                                    color: "#FFC540",
                                    marginBottom: "30px"
                                }}>
                                    Scan this QR on your authenticator App
                                </Typography>
                                <Item>
                                    <img src={authenticatorImage} alt="" style={{ height: '200px', width: '200px' }} />
                                </Item>

                                <Box onSubmit={handleSubmit} noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 3, gap: '15px', width: '100%' }}>

                                        <StyledButtonContained variant="contained"
                                            type="button"
                                            onClick={() => { setVisiblity('forgetPassword') }}
                                            sx={{
                                                width: '140px',
                                                height: '50px',
                                                borderRadius: '30px'
                                            }}>
                                            Cancel
                                        </StyledButtonContained>
                                        <StyledButtonContained variant="contained"
                                            type="button"
                                            onClick={() => { setVisiblity('enterOtpMailForgotPassword'); }}
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Next
                                        </StyledButtonContained>
                                    </Box>

                                </Box>
                            </Box>
                        </Item>
                    </Grid>}

                    {/* ***** Forgot Password */}
                    {visibleState?.forgetPassword && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Item key="emailForgotPasswordBox">
                            <form onSubmit={handleSubmit(submitForgotPassword)}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        color: '#FFF',
                                        fontFamily: ' "Poppins", sans-serif !important',
                                        fontSize: '24px',
                                        fontWeight: '700',
                                    }}
                                >
                                    <Typography component="h1" variant="h5" sx={{
                                        fontFamily: ' "Poppins", sans-serif !important',
                                        fontWeight: '400px',
                                        fontSize: '20px'
                                    }}>
                                        Please enter your email address
                                    </Typography>
                                    <Box noValidate sx={{
                                        width: '100%',
                                        mt: 4,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>

                                        <TextField
                                            key="emailForgotPassword"
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="emailForgotPassword"
                                            name="emailForgotPassword"
                                            type="email"
                                            {...register('emailForgotPassword', { required: true, })}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& fieldset': {
                                                        borderRadius: '8px',
                                                        borderColor: '#C7CCD0',
                                                    },
                                                    '&:hover fieldset': {
                                                        borderColor: '#C7CCD0',
                                                    },
                                                    '&.Mui-focused fieldset': {
                                                        borderColor: '#C7CCD0',
                                                    }
                                                },
                                                '& .MuiOutlinedInput-input': {

                                                    color: '#FFFFFF',
                                                },
                                                '& .MuiInputLabel-root': {
                                                    color: 'rgba(255, 255, 255, 0.5)',
                                                    '&.Mui-focused': {
                                                        color: '#FFC540'
                                                    },
                                                },
                                            }}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, gap: '15px', width: '100%' }}>
                                            <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                                <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => { setVisiblity('login') }}>Back to Login</a>
                                            </Typography>
                                            <StyledButtonContained variant="contained"
                                                type='submit'
                                                // onClick={submitForgotPassword}
                                                sx={{
                                                    width: '240px',
                                                    height: '50px',
                                                    borderRadius: '30px'
                                                }}>
                                                Send OTP
                                            </StyledButtonContained>

                                        </Box>

                                    </Box>
                                </Box>
                            </form>
                        </Item>
                    </Grid>}

                    {/* ***** Enter OTP Mail forgotPassword 1*/}
                    {visibleState?.enterOtpMailForgotPassword && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                color: '#FFF',
                                fontFamily: ' "Poppins", sans-serif !important',
                                fontSize: '24px',
                                fontWeight: '700',
                            }}
                        >
                            <Typography component="h1" variant="h5" sx={{
                                fontFamily: ' "Poppins", sans-serif !important',
                                fontWeight: '600px',
                                fontSize: '24px'
                            }}>
                                Enter {userMFAOption != 'authenticator' ? 'OTP' : 'MFA'}
                            </Typography>

                            <Typography style={{ fontSize: "14px", color: "#C7CCD0", fontWeight: "400" }}>An MFA Code has been sent to your {userMFAOption}.</Typography>
                            <Box noValidate sx={{
                                width: '100%',
                                mt: 5,
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'start',
                                flexDirection: 'column'
                            }}>
                                <OtpInput alignItems="start"
                                    value={otpForgotPassword}
                                    onChange={setOtpForgotPassword}
                                    numInputs={6}
                                    renderSeparator={<span style={{
                                        fontSize: "22px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                    }} ></span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        width: "20%",
                                        marginBottom: "10px",
                                        height: "70px",
                                        borderRadius: "8px",
                                        border: "1px solid var(--Gray-1, #C7CCD0)",
                                        background: "var(--Charcoal, #3A3E3F)",
                                        outline: "none",
                                        color: "#fff",
                                        fontSize: "22px",
                                        fontWeight: "400"

                                    }}
                                />
                                <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                    {
                                        userMFAOption == 'authenticator' ?
                                            <>
                                                <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => submitForgotPasswordBypass_is_authenticator_reg()}>Scan QR</a> or&nbsp;
                                            </> : ''
                                    }{
                                        userMFAOption != 'authenticator' ?
                                            <>
                                                <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={submitForgotPassword}>Resend OTP</a> or&nbsp;
                                            </> :
                                            ''
                                    }
                                    <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => { setVisiblity('login') }}>Back to Login</a>
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5, gap: '15px', width: '100%' }}>
                                    <StyledButtonContained variant="contained"
                                        // onClick={()=>{setVisiblity('enterChangePasswordForgotPassword')}}
                                        onClick={() => { verifyForgotPassword() }}
                                        type="button"
                                        sx={{
                                            width: '140px',
                                            height: '50px',
                                            borderRadius: '30px'
                                        }}>
                                        Submit OTP
                                    </StyledButtonContained>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>}
                    {/* ***** Enter OTP Mail forgotPassword 2*/}
                    {visibleState?.enterOtpMailForgotPasswordTwo && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                color: '#FFF',
                                fontFamily: ' "Poppins", sans-serif !important',
                                fontSize: '24px',
                                fontWeight: '700',
                            }}
                        >
                            <Typography component="h1" variant="h5" sx={{
                                fontFamily: ' "Poppins", sans-serif !important',
                                fontWeight: '600px',
                                fontSize: '24px'
                            }}>
                                Enter {userMFAOption != 'authenticator' ? 'OTP' : 'MFA'}
                            </Typography>

                            <Typography style={{ fontSize: "14px", color: "#C7CCD0", fontWeight: "400" }}>An MFA Code has been sent to your {userMFAOption}.</Typography>
                            <Box noValidate sx={{
                                width: '100%',
                                mt: 5,
                                display: 'flex',
                                alignItems: 'start',
                                justifyContent: 'start',
                                flexDirection: 'column'
                            }}>
                                <OtpInput alignItems="start"
                                    value={otpForgotPasswordTwo}
                                    onChange={setOtpForgotPasswordTwo}
                                    numInputs={6}
                                    renderSeparator={<span style={{
                                        fontSize: "22px",
                                        marginLeft: "2%",
                                        marginRight: "2%",
                                    }} ></span>}
                                    renderInput={(props) => <input {...props} />}
                                    inputStyle={{
                                        width: "20%",
                                        marginBottom: "10px",
                                        height: "70px",
                                        borderRadius: "8px",
                                        border: "1px solid var(--Gray-1, #C7CCD0)",
                                        background: "var(--Charcoal, #3A3E3F)",
                                        outline: "none",
                                        color: "#fff",
                                        fontSize: "22px",
                                        fontWeight: "400"

                                    }}
                                />
                                <Typography style={{ textAlign: "end", width: "100%", marginTop: "12px", marginBottom: "12px" }}>
                                    {
                                        userMFAOption != 'authenticator' ?
                                            <>
                                                <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={onChangePasswordSubmit}>Resend OTP</a> or
                                            </> :
                                            ''
                                    } <a href="#" style={{ color: "#C7CCD0", fontSize: "14px", fontWeight: "24px" }} onClick={() => { setVisiblity('login') }}>Back to Login</a>
                                </Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 5, gap: '15px', width: '100%' }}>
                                    <StyledButtonContained variant="contained"
                                        // onClick={()=>{setVisiblity('enterChangePasswordForgotPassword')}}
                                        onClick={() => { changePassword() }}
                                        type="button"
                                        sx={{
                                            width: '140px',
                                            height: '50px'
                                        }}>
                                        Submit OTP
                                    </StyledButtonContained>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>}

                    {/* ***** Confirm Password Form */}
                    {visibleState?.enterChangePasswordForgotPassword && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <form onSubmit={handleSubmit(onChangePasswordSubmit)}>
                            {/* <Item> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >
                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px'
                                }}>
                                    Create New Password
                                </Typography>
                                <Box noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>

                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        // id="username"
                                        type="password"
                                        label="Enter Password"
                                        name="password1"
                                        {...register("password1", { required: true })}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#C7CCD0',
                                                }
                                            },
                                            '& .MuiOutlinedInput-input': {
                                                //fontFamily: 'Poppins', 
                                                //fontWeight: 400, 
                                                //lineHeight: '33px', 
                                                //fontSize: '22px', 
                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                '&.Mui-focused': {
                                                    color: '#FFC540'
                                                },
                                            },
                                        }}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="RepeatPassword"
                                        label="Repeat Password"
                                        type="password"
                                        // id="password"
                                        {...register("password2", {
                                            required: true,
                                            pattern: "",
                                            validate: (val) => {
                                                if (watch('password1') != val) {
                                                    return "Your passwords do not match";
                                                }
                                                else if (!passwordRegex.test(val)) {
                                                    return "Please set a password with minimum length of 8 characters, containing at least 1 uppercase letter, 1 lowercase letter, and 1 digit."
                                                }
                                            }
                                        })}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                '& fieldset': {
                                                    borderRadius: '8px',
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&:hover fieldset': {
                                                    borderColor: '#C7CCD0',
                                                },
                                                '&.Mui-focused fieldset': {
                                                    borderColor: '#C7CCD0',
                                                }
                                            },
                                            '& .MuiOutlinedInput-input': {

                                                color: '#FFFFFF',
                                            },
                                            '& .MuiInputLabel-root': {
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                '&.Mui-focused': {
                                                    color: '#FFC540'
                                                },
                                            },
                                        }}

                                    />
                                    {errors.password2 && <p style={{
                                        fontSize: '12px',
                                        color: '#eb4b10',
                                        width: '100%',
                                        textAlign: 'left',
                                        marginTop: '-2px'
                                    }}>{errors.password2?.message}</p>}
                                    {/* {error.password2&& <p>{error.password2}</p>} */}
                                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, gap: '15px', width: '100%' }}>
                                        <StyledButtonContained variant="contained"
                                            onClick={() => { setVisiblity('login') }}
                                            type="button"
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Cancel
                                        </StyledButtonContained>
                                        <StyledButtonContained variant="contained"
                                            type="submit"
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Submit
                                        </StyledButtonContained>
                                    </Box>
                                </Box>
                            </Box>
                            {/* </Item> */}
                        </form>
                    </Grid>}
                    {/* ***** Email Sent With Password */}
                    {visibleState?.emailSentWithPassword && <Grid item xs={6} className={classes.loginContainer} style={{ borderRadius: "0px 10px 10px 0px", background: " #3A3E3F", padding: "35px" }}>
                        <Item>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    color: '#FFF',
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontSize: '24px',
                                    fontWeight: '700',
                                }}
                            >
                                <Item>
                                    <img src="/assets/img/email-fast.svg" alt="" />
                                </Item>
                                <Typography component="h1" variant="h5" sx={{
                                    fontFamily: ' "Poppins", sans-serif !important',
                                    fontWeight: '600px',
                                    fontSize: '24px',
                                    marginTop: '12px',
                                }}>
                                    Password changed
                                </Typography>
                                <Typography style={{ fontSize: "18px", color: "#C7CCD0", fontWeight: "400" }}>
                                    Password changed successfully.
                                </Typography>
                                <Box onSubmit={handleSubmit} noValidate sx={{
                                    width: '100%',
                                    mt: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, gap: '15px', width: '100%' }}>

                                        <StyledButtonContained variant="contained"
                                            type="button"
                                            onClick={() => { setVisiblity('login') }}
                                            sx={{
                                                width: '140px',
                                                height: '50px'
                                            }}>
                                            Login
                                        </StyledButtonContained>
                                    </Box>

                                </Box>
                            </Box>
                        </Item>
                    </Grid>}

                </Grid>

            </Container>

        </Paper >
    )
}


const useStyles = makeStyles({
    heading: {
        color: " #4B4B4D",
        fontSize: "21px",
        fontWeight: "500px",
        lineHeight: "40px",
    },
    mainContainer: {
        display: "flex  !important",
        justifyContent: "center  !important",
        alignItems: "center  !important",
        height: "100%  !important",
    },
    imgContainer: {
        display: "flex !important ",
        flexDirection: "column  !important",
        height: "100%  !important",
        alignItems: "flex-start  !important",
        justifyContent: "center  !important",
        padding: "35px  !important",
    },
    loginContainer: {
        display: "flex !important",
        width: "100% !important",
        flexDirection: "column !important",
        height: "100% !important",
        justifyContent: "center !important",

    }
})